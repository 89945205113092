import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Col,
  Row,
  CardBody,
  CardTitle,
  CardText,
  CardImg,
  Link,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import GoogleMap from './GoogleMap';
import ContactUsForm from './ContactUsForm';
import { gem1 } from '../../assets/images';

const ArticleCard1 = () => {
  const { t } = useTranslation();

  return (
    <div className="content-card">
      <Helmet>
        <title>
          Learn About History of Gem Trades in Srilanka - Bijouterie Joyaux
          Canada - Natural Gems, Precious, Semi Precious Stones, Gemstones
          montreal
        </title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.bijouteriejoyaux.com" />
        <meta
          property="og:title"
          content="Learn About History of Gem Trades in Srilanka - Bijouterie Joyaux
          Canada - Natural Gems, Precious, Semi Precious Stones, Gemstones
          montreal"
        />
        <meta
          property="og:description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality."
        />
        <meta
          name="description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality. "
        />
        <meta
          name="keywords"
          content="Learn Gemstones,Gemstones Montreal,Canada Gemstones,Buy Gemstones Canada, gemstones, wholesale gemstones, gemstone wholesale, gemstone dealer, gem dealer, wholesale gemstone dealer, fine gems dealer, vente de pirre précieuse, grossiste pierre précieuse, marchand de pierres précieuses"
        />
        <link rel="Learn Gems" href="https://www.bijouteriejoyaux.com" />
      </Helmet>

      <Card className="card">
        {/* <div class="text">🎄🎄🎄🎄🎄🎄🎄🎄🎄🎄🎄</div> */}

        {/* <ul class="lightrope">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul> */}

        <CardTitle className="card-title-learn">
          History of Gem Trades in Srilanka
        </CardTitle>

        <Row>
          <Col sm={12} md={6}>
            <CardBody className="card-body">
              <Card className="contact-us-card">
                <CardText>
                  <Card>
                    <CardImg
                      alt="Gemstones Mining,Gemstones Canada"
                      src={gem1}
                      className="contact-us-card-image1"
                    />
                  </Card>
                  <br />
                  <Col lg="12" className="text-left">
                    Gem industry in Sri Lanka has a great history mainly due to
                    the Island's gem mines and the Srilanka stands today as one
                    of the world's leading gem suppliers with products of unique
                    quality. From past to present Srilanka is known as
                    Rathnaeepa because the country stands as the{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Sapphire</a>{' '}
                    capital of the world.75 different kinds of precious and
                    semi-precious gemstones were exported and sapphire varieties
                    make nearly 85% of the export share at the moment.
                  </Col>
                  <br />
                  <h6>
                    <u>
                      <a href={'https://bijouteriejoyaux.com'}>
                        <a href={'https://bijouteriejoyaux.com'}>Gemstone</a>{' '}
                      </a>{' '}
                      minings in Srilanka
                    </u>
                  </h6>
                  <Col lg="12" className="text-left">
                    The major gem bearing areas in Srilanaka are the
                    Sabaragamuwa province, Morawaka Rakwana hills, Matale-Elaera
                    region, Badulla-Passaa region and also smaller areas around
                    places such as Kataragama, Ambalangoda , Okkampiitya ,
                    Monaragala, Horana and Hatton. The occurrence of most of the{' '}
                    <a href={'https://bijouteriejoyaux.com'}>gemstones</a> in
                    Srilanaka is in the metamorphic type of rocks in the
                    highland/ South western complex.The global recognition
                    achieved by the Ceylon Sapphire speaks volumes to the
                    timeless beauty and resounding durability present in the
                    precious stone. The Ceylon Sapphires achieves all the
                    benchmarks of quality, colour purity and precisely cut
                    designs that catapult the stone's name to the top of the
                    international market where it flourished under the constant
                    demand of foreign buyers.
                  </Col>
                  <br />
                  <h6>
                    <u>
                      Development of the gem trade and industry after
                      independence
                    </u>
                  </h6>
                  <br />
                  <Col lg="12" className="text-left">
                    After Sri Lanka gained its independence in 1948 the status
                    quo as far as exploitation of gem resources were concerned
                    continued to be maintained and remained in the hands of
                    enterprising private individuals. Besides the traditional
                    gem-mining areas in the district of Ratnapura in the
                    Sabaragamuwa Province, several new mines were discovered
                    accidentally and opened for exploitation in other districts
                    such as Matale in the Central Province, Matara in the
                    Southern province and Badulla and Moneragala in the Uva
                    Province.
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    Visit{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International Inc
                    </a>{' '}
                    for more information.{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                    is a coloured gemstone wholesaler company based in
                    Montreal,Canada proud to present to you quality, and a vast
                    selection of natural gemstones.
                  </Col>
                </CardText>
              </Card>
            </CardBody>
          </Col>
          <Col sm={12} md={6} className="contact-us-right-col">
            <Card className="contact-us-card">
              <CardBody>
                <CardTitle className="card-title-learn-sub">
                  <a href={'https://bijouteriejoyaux.com'}>Gem Island</a>
                </CardTitle>

                <br />
                <CardText tag="div">
                  <Col lg="12" className="text-left">
                    Sri Lanka has had an immense reputation from time immemorial
                    for different varieties of gemstones. According to legend
                    King Solomon wooed Queen Sheba, by presenting her with
                    jewelry set with gemstones from Sri Lanka. Almost three
                    millennia (29 centuries) later, Prince Charles, mesmerized
                    Lady Diana, with an engagement ring adorned with a priceless
                    blue sapphire from Sri Lanka.
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    Srilanka has been unique and singularly reputed for among
                    the countries with high concentration of gem minerals world
                    over, such as Myanmar, South Africa,Brazil , Tanzania,
                    Colombia, Russia ,Madagascar and several other countries for
                    the precious and semi precious stones. These few records
                    will prove that Srilanka had earned high reputation over the
                    world as store house of{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Gemstones</a> .
                    Ceylon is unique to claim in producing a greater range of
                    different gems totalling upto about 75 varieties and
                    sub-varieties. A greater number of rare gem varieties are
                    also found in Sri Lanka.
                  </Col>
                  <br />

                  <br />
                  <h6>
                    <u>
                      History of Gem Trade industry in Ceylon and Its historical
                      movement
                    </u>
                  </h6>
                  <br />

                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>Gemstone</a> mining
                    in Sri Lanka is mostly from secondary deposits. The gravels
                    yield sapphire, ruby, cat's-eye and other chrysoberyls,
                    spinel, garnet, beryl, tourmaline, topaz, quartz, and many
                    other gemstones. Sri Lanka's gem-bearing gravels, called
                    illam, are some of the richest in the world.Gem trade
                    history in Srilanka go back to first century AD.This is
                    proved by historical records made by astronomers
                    ,geographers, and early travellers. As to many Gemmological
                    books, Srilanka owns one of the oldest mines and sources in
                    gemstones in the world. Discovering stone implements and
                    tools which date back to Stone Age in caves in Sabaragamuwa
                    province proved this.
                    <br />
                    <li>
                      Greeks and Romans used Ceylon Sapphires and Rubies to make
                      jewellery from approximately 480 BC onwards.
                    </li>
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    “Periplus of the Erythraean Sea” one of the greatest
                    merchants guide mentions that Western jewelleries are made
                    using precious stones exported from this country.. The
                    ““Periplus of the Erythraean Sea” is an eyewitness account
                    of ancient travel to Africa and India via the Red Sea
                    written by an unknown.
                    <li>
                      Arabs and Persians have established trade with the East
                      during the 4th and 5th centuries the, and Srilanka was
                      known as “Serandib”. ‘Traves of Sinbad the Sailor’ Arab
                      epic work also called as Tales of Arabians Nights referred
                      Srilanka as Serandib.
                    </li>
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    <li>
                      Vasco de Gama’s comments on the{' '}
                      <a href={'https://bijouteriejoyaux.com'}>Gemstones</a> of
                      Sri Lanka The Portuguese explorer Vasco de Gama whose
                      voyages to India from 1497 to 1524 AD, opened up the sea
                      route round the Cape of Good Hope, from western Europe to
                      the East, eventually making Portugal a world power, said
                      of Sri Lanka, “Ceylon has all the fine cinnamon of the
                      Indies and the best sapphires.” Throughout the history,
                      Sri Lanka has unearthed Sapphires of large size and
                      impeccable quality such as the Star of Adam, The Star of
                      India, The Blue Giant of Orient and the Logan Sapphire
                      which make up some of the largest Sapphires in the world.
                    </li>
                    Visit{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International Inc
                    </a>{' '}
                    for more information
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    So these are few interesting facts about Gem Trade History
                    in Srilanka. We hope you would have enjoyed reading this
                    article. Learn and buy gemstones in Canada,Montreal from
                    visiting our website{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                    is a{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      gemstones dealer
                    </a>
                    (
                    <a href={'https://bijouteriejoyaux.com'}>
                      grossiste pierre précieuse
                    </a>
                    , marchand de pierres précieuses) Company in{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Canada-Montreal</a>{' '}
                    which has{' '}
                    <a
                      href={
                        'https://kids.nationalgeographic.com/science/topic/gemstones'
                      }
                    >
                      Wholesale gemstones
                    </a>{' '}
                    with wide range of Fine Quality Certified Natural{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Presious and Semi Precious gemstones
                    </a>
                    . This is the best place to buy fine quality{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      wholesale gemstones Canada
                    </a>
                    <br />
                    <br />
                    Visit{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International Inc
                    </a>
                  </Col>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ArticleCard1;
