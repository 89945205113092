import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectPreciousAllDataForCalibrated } from '../../../selectors/apiSelectors';
import PreciousStones from '../../UI/PreciousStones/PreciousStones';

const CalibratedSapphire = () => {
  const preciousAllDataForCalibrated = useSelector(
    selectPreciousAllDataForCalibrated
  );

  const handlePreciousCardClick = (category) => () => {
    history.push(`/calibratedSapphire/detail?id=${category.id}`);
  };

  const { t } = useTranslation();

  const history = useHistory();

  if (preciousAllDataForCalibrated.length === 0) {
    history.push('/');
  }

  return (
    <PreciousStones
      preciousAllData={preciousAllDataForCalibrated}
      handlePreciousCardClick={handlePreciousCardClick}
      title={t('calibrated-sapphire-title')}
    />
  );
};

export default CalibratedSapphire;
