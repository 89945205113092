import { createSelector } from 'reselect';
import { calibratedSapphire } from '../utils/constant';

const selectApi = (state) => state.apiReducer;

export const selectPreciousAllData = createSelector(
  selectApi,
  (apiReducer) => apiReducer.preciousAllData ?? []
);

export const selectPreciousAllDataWithCalibrated = createSelector(
  selectPreciousAllData,
  (preciousAllData) => [...preciousAllData, calibratedSapphire]
);

export const selectPreciousAllDataForCalibrated = createSelector(
  selectPreciousAllData,
  (preciousAllData) =>
    preciousAllData.filter((data) => ![20, 17].includes(data.id))
);
