import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Container, Table } from 'reactstrap';
import calibratedDetails from '../../../calibrated-details/calibrated-details.json';
import { useQuery } from '../../../hooks.js/useQuery';
import BackButton from '../../UI/BackButton';
import Inquiry from '../../UI/Inquiry';

const CalibratedSapphireDetail = () => {
  const query = useQuery();
  const { t } = useTranslation();
  const history = useHistory();

  const handleBackClick = () => {
    history.goBack();
  };

  const calibratedDataObj = calibratedDetails[query.get('id')];

  return (
    <Container className="main-container-calibrated-sapphire">
      <BackButton
        handleClick={handleBackClick}
        text={t('calibrated-sapphire-back')}
      />

      <div className="calibrated-table col-sm-12 col-md-8 col-lg-8">
        <h3>{calibratedDataObj.title}</h3>
        <Table striped>
          <tr className="calibrated-table-header">
            <th>{t('calibrated-table-header-item-no')}</th>
            <th>{t('calibrated-table-header-shape')}</th>
            <th>{t('calibrated-table-header-colour')}</th>
            <th>{t('calibrated-table-header-mm')}</th>
          </tr>

          <tbody>
            {calibratedDataObj.data.map((row) => (
              <tr>
                <td>{row['ITEM NO']}</td>
                <td>{row.SHAPE}</td>
                <td>{row.COLOUR}</td>
                <td>{row.MM}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Inquiry
          lotNumber={`${t('calibrated-sapphire-title')} ${
            calibratedDataObj.type
          }`}
          label={t('post-detail-inquiry-button')}
          color="secondary"
          className="post-view-inquiry-button"
        />
      </div>
    </Container>
  );
};

export default CalibratedSapphireDetail;
