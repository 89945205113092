import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Col,
  Row,
  CardBody,
  CardTitle,
  CardText,
  CardImg,
  Link,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import GoogleMap from './GoogleMap';
import ContactUsForm from './ContactUsForm';
import { shutterstock_1 } from '../../assets/images';

const ArticleCard17 = () => {
  const { t } = useTranslation();

  return (
    <div className="content-card">
      <Helmet>
        <title>
          Learn about Semi Precious Gemsotnes - Bijouterie Joyaux Canada -
          Natural Gems, Precious, Semi Precious Stones, Gemstones montreal
        </title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.bijouteriejoyaux.com" />
        <meta
          property="og:title"
          content="Learn about Semi Precious Gemsotnes - Bijouterie Joyaux
          Canada - Natural Gems, Precious, Semi Precious Stones, Gemstones
          montreal"
        />
        <meta
          property="og:description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality."
        />
        <meta
          name="description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality. "
        />
        <meta
          name="keywords"
          content="Learn Gemstones,Gemstones Montreal,Canada Gemstones,Buy Gemstones Canada, gemstones, wholesale gemstones, gemstone wholesale, gemstone dealer, gem dealer, wholesale gemstone dealer, fine gems dealer, vente de pirre précieuse, grossiste pierre précieuse, marchand de pierres précieuses"
        />
        <link rel="Learn Gems" href="https://www.bijouteriejoyaux.com" />
      </Helmet>
      <Card>
        <Row>
          <Col sm={12} md={6}>
            <CardBody>
              <Card className="contact-us-card">
                <CardTitle className="card-title-learn">
                  Learn about Semi Precious Gemsotnes
                </CardTitle>
                <CardText>
                  <Card className="contact-us-card">
                    <CardImg
                      alt="Gemstones Mining,Gemstones Canada"
                      src={shutterstock_1}
                      className="contact-us-card-image6"
                    />
                  </Card>
                  <br />
                  <Col lg="12" className="text-left">
                    Precious and semi-precious stones that are used in jewelry
                    are called gemstones. Typically, these stones are cut and
                    refined minerals. However, gemstones are not always composed
                    of minerals. Sometimes precise rocks, or organic resources
                    are considered gemstones and are also used in jewelry and
                    embellishments. Stones that fall under this category include
                    amber and jet.
                  </Col>
                  <br />
                  <h6>
                    <u>Precious vs Semi Precious</u>
                  </h6>
                  <br />
                  <Col lg="12" className="text-left">
                    They are essentially semi-precious{' '}
                    <a href={'https://bijouteriejoyaux.com'}>gemstones</a>{' '}
                    mstones and come in a variety of colors. On the other hand,
                    the classic and precious rubies, sapphires, emeralds and
                    diamonds still continue to rule the hearts. So what really
                    is the difference between precious and the{' '}
                    <a href={'https://bijouteriejoyaux.com'}>semi-precious</a>{' '}
                    stones? While the differences can be based on an
                    individual’s choice and likes As ingrained in the collective
                    awareness as they may be, the labels "precious" and
                    "semi-precious" can be very deceiving. They aren't
                    scientifically based nor reflect the grade or quality of a
                    gem. Emerald (precious) and aquamarine (semi-precious), for
                    example, are both varieties of beryl.
                  </Col>
                  <br />

                  <h6>
                    <u>
                      Usage of{' '}
                      <a href={'https://bijouteriejoyaux.com'}>Semi Precious</a>{' '}
                      Stones
                    </u>
                  </h6>
                  <Col lg="12" className="text-left">
                    One of the major reasons why certain{' '}
                    <a href={'https://bijouteriejoyaux.com'}>semi precious</a>{' '}
                    stones are used more than others is to do with the hardness
                    of the stone. The harder the stone, the more resistant and
                    durable the jewellery piece would be.{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Gemstones</a> with
                    hardness 7 or higher, on the Mohs Hardness Scale are best
                    for jewellery – Amethyst, Yellow Topaz, Citrine, Rose
                    Quartz, Moonstone, Rainbow stone are some examples.Different
                    Stones have different cuts which enhance their look and
                    brightness. For example, Opal is smooth and round, whereas
                    Amethyst can be cut in many different ways – round, spiral,
                    marquise, pear, baguette, square and so on.Any gemstones
                    that is not a diamond, ruby, emerald or sapphire is a semi-
                    precious{' '}
                    <a href={'https://bijouteriejoyaux.com'}>gemstone</a>.
                    Calling a gemstone semi-precious does not mean it is less
                    valuable than precious gemstones. Semi-precious gemstones
                    are just usually more abundant (but there are a few
                    exceptions).
                  </Col>
                </CardText>
              </Card>
            </CardBody>
          </Col>
          <Col sm={12} md={6} className="contact-us-right-col">
            <Card className="contact-us-card">
              <CardBody>
                <CardText tag="div">
                  <h6>
                    <u>Several factors define the value of a gemstone</u>
                  </h6>
                  <br />
                  <Col lg="12" className="text-left">
                    The value given to semi-precious gemstones depend largely on
                    color, availably and quality. Because these gemstones
                    typically have more sources, they are a good choice for
                    larger, clean-eye stones and come in a rainbow of colors.
                    Rarity, color, brilliance, transparency, durability, cut,
                    optical effects, and size are a few elements at play when
                    determining the worth of a gem. Some specimens called
                    semi-precious are rarer and more valuable than the four
                    precious gems. Jade, opal, and pearl, for example, are often
                    considered precious.
                  </Col>
                  <Col lg="12" className="text-left">
                    Amethyst was also a precious stone in the past until large
                    deposits were found in Brazil and other locations. Aside
                    from rarity, size and quality also increase the price of a
                    semi-precious gemstone, making it more expensive than some
                    low-quality precious specimens. The two labels are
                    disregarded by experts and are mostly used in the industry
                    to highlight the four more popular rare gems in higher
                    demand.
                  </Col>
                  <br />
                  <br />
                  <h6>
                    <u>
                      How are Gemstones classified as{' '}
                      <a href={'https://bijouteriejoyaux.com'}>
                        Precious / Semiprecious
                      </a>
                      ?
                    </u>
                  </h6>
                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>Gemstones</a> were
                    first classified, considering their value at that time, into
                    categories of "precious" or "semiprecious" in the mid-1800s.
                    These terms quickly become popular and were used throughout
                    the world.
                  </Col>
                  <ol>
                    <li>
                      <h6>
                        <u>Precious Stones</u>
                      </h6>
                      <Col lg="12" className="text-left">
                        "Precious stones" is a name that is usually used in
                        reference to diamonds, rubies, emeralds and{' '}
                        <a href={'https://bijouteriejoyaux.com'}>Sapphires</a>{' '}
                        .Traditionally, these four precious stones have been the
                        most expensive and sought after stones. Sometimes
                        pearls, opals or jades were listed as precious
                        gemstones, but more often they are considered
                        semi-precious.
                      </Col>
                    </li>
                    <li>
                      {' '}
                      <h6>
                        <u>Semi Precious Stones</u>
                      </h6>
                      <Col lg="12" className="text-left">
                        Every other gemstone that isn’t one of those four is
                        considered to be semi precious. The list goes on and on,
                        but some of the more common ones are: alexandrite,
                        agate, amethyst, aquamarine, garnet, lapis lazuli,
                        moonstone, opal, pearl, peridot, rose quartz, spinel,
                        tanzanite, tourmaline, turquoise and zircon.
                      </Col>
                    </li>
                  </ol>
                  <Col lg="12" className="text-left">
                    Some of these can be worth much more than a precious stone.
                    As an example, many natural pearls garner huge prices, often
                    worth more than a low quality precious diamond, ruby,
                    emerald or sapphire. Spinels are another example.
                    Additionally, many semi precious stones can be more rare
                    than some of the precious. Demantoid garnets or tsavorite
                    garnets and many other semi precious gemstones are hard to
                    find, harder to mine and produced in much lower qualities
                    than the precious gemstones.
                  </Col>
                  <Col lg="12" className="text-left">
                    So these are few interesting facts about{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Semi Precious gemstones
                    </a>{' '}
                    . We hope you would have enjoyed reading this article. Learn
                    and buy gemstones in Canada,Montreal from visiting our
                    website{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                    is a{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      gemstones dealer
                    </a>
                    (
                    <a href={'https://bijouteriejoyaux.com'}>
                      grossiste pierre précieuse
                    </a>
                    , marchand de pierres précieuses) Company in{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Canada-Montreal</a>{' '}
                    which has{' '}
                    <a
                      href={
                        'https://kids.nationalgeographic.com/science/topic/gemstones'
                      }
                    >
                      Wholesale gemstones
                    </a>{' '}
                    with wide range of Fine Quality Certified Natural{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Presious and Semi Precious gemstones
                    </a>
                    . This is the best place to buy fine quality{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      wholesale gemstones Canada
                    </a>
                    <br />
                    <br />
                    Visit{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International Inc
                    </a>
                  </Col>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ArticleCard17;
