import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Col,
  Row,
  CardBody,
  CardTitle,
  CardText,
  CardImg,
  Link,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import GoogleMap from './GoogleMap';
import ContactUsForm from './ContactUsForm';
import {
  gem555,
  loganblue,
  bluebella,
  staradam,
  starofindia,
} from '../../assets/images';

const ArticleCard5 = () => {
  const { t } = useTranslation();

  return (
    <div className="content-card">
      <Helmet>
        <title>
          World Famous Gemstones From Srilanka - Bijouterie Joyaux
          Canada,Montreal - Natural Gems, Precious, Semi Precious Stones,
          Gemstones montreal
        </title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.bijouteriejoyaux.com" />
        <meta
          property="og:title"
          content="World Famous Gemstones From Srilanka - Bijouterie Joyaux
          Canada - Natural Gems, Precious, Semi Precious Stones, Gemstones
          montreal"
        />
        <meta
          property="og:description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality."
        />
        <meta
          name="description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality. "
        />
        <meta
          name="keywords"
          content="Learn Gemstones,Gemstones Montreal,Canada Gemstones,Buy Gemstones Canada, gemstones, wholesale gemstones, gemstone wholesale, gemstone dealer, gem dealer, wholesale gemstone dealer, fine gems dealer, vente de pirre précieuse, grossiste pierre précieuse, marchand de pierres précieuses"
        />
        <link rel="Learn Gems" href="https://www.bijouteriejoyaux.com" />
      </Helmet>
      <Card>
        <Row>
          <Col sm={12} md={6}>
            <CardBody>
              <Card className="contact-us-card">
                <CardTitle className="card-title-learn">
                  World Famous Gemstones From Srilanka
                </CardTitle>
                <CardText>
                  <h6>
                    <u>GIANT OF THE ORIENT</u>
                  </h6>
                  <Card className="contact-us-card">
                    <CardImg
                      alt="Gemstones Mining,Gemstones Canada"
                      src={gem555}
                      className="contact-us-card-image3"
                    />
                  </Card>
                  <br />
                  <Col lg="12" className="text-left">
                    Giant Blue{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Sapphire</a>{' '}
                    reportedly found near Adams Peak in Sri Lanka in 1902. A
                    leading exporter at the time O.L.M Macan Marker and Co
                    purchased the rough weighed more than 600 carats. The Local
                    cutter worked the rough which went under cutting and
                    polishing that transformed it into 466 ct. This impressive
                    corn flower hued cushion cut blue colors is one of the
                    rarest and it was the largest faceted{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Blue Sapphires</a>{' '}
                    of its kind at that time.
                  </Col>

                  <br />

                  <h6>
                    <u>LOGAN BLUE SAPPHIRE</u>
                  </h6>
                  <Card className="contact-us-card">
                    <CardImg
                      alt="Gemstones Mining,Gemstones Canada"
                      src={loganblue}
                      className="contact-us-card-image3"
                    />
                  </Card>
                  <br />
                  <Col lg="12" className="text-left">
                    Rich blue internally flawless{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Blue Sapphire</a>{' '}
                    weighing at 423 ct perhaps the second largest blue sapphire
                    in the world. Sri Lanka has avoided exporting rough stones
                    since ancient times. Logan sapphire originally found and cut
                    in Sri Lanka which boasts the cutting and mining industry
                    dating over two millennia.
                  </Col>
                  <br />
                  <h6>
                    <u>BLUE BELLA OF ASIA</u>
                  </h6>
                  <Card className="contact-us-card">
                    <CardImg
                      alt="Gemstones Mining,Gemstones Canada"
                      src={bluebella}
                      className="contact-us-card-image3"
                    />
                  </Card>
                  <br />
                  <Col lg="12" className="text-left">
                    The blue bella of Asia is a 400 ct highly desirable corn
                    flower Blue sapphire said to have found in a paddy field
                    Rathnapura in 1926. The word "belle" refers to the
                    extraordinary beauty of this enormous{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Blue Sapphire</a>{' '}
                    owned by Lord Naffield of Britain. This blue bella
                    reappeared in Geneva Switzerland at Christie's Auction.
                  </Col>

                  <br />
                </CardText>
              </Card>
            </CardBody>
          </Col>
          <Col sm={12} md={6} className="contact-us-right-col">
            <Card className="contact-us-card">
              <CardBody>
                <h6>
                  <u>STAR OF ADAM</u>
                </h6>
                <br />
                <Card className="contact-us-card">
                  <CardImg
                    alt="Gemstones Mining,Gemstones Canada"
                    src={staradam}
                    className="contact-us-card-image3"
                  />
                </Card>
                <br />
                <Col lg="12" className="text-left">
                  Sri Lanka is the proud source of world three largest{' '}
                  <a href={'https://bijouteriejoyaux.com'}>Sapphires</a> ; a
                  Blue giant of the Orient, Logan Blue Sapphire and the Blue
                  Belle of Asia, all ranging between 400 – 466 carats. In
                  addition to the world’s largest sapphires, Sri Lanka has also
                  been the source of some of the largest star{' '}
                  <a href={'https://bijouteriejoyaux.com'}>Sapphires</a> in the
                  world, the largest being the Star of Adam, an oval-shaped blue{' '}
                  <a href={'https://bijouteriejoyaux.com'}>Sapphire</a> weighing
                  1,404.49 carats.
                </Col>
                <br />
                <h6>
                  <u>STAR OF INDIA</u>
                </h6>
                <br />
                <Card className="contact-us-card">
                  <CardImg
                    alt="Gemstones Mining,Gemstones Canada"
                    src={starofindia}
                    className="contact-us-card-image3"
                  />
                </Card>
                <br />
                <Col lg="12" className="text-left">
                  Incidentally, another large{' '}
                  <a href={'https://bijouteriejoyaux.com'}>Star Sapphire</a>{' '}
                  known as 'Star of India' too originated from Sri Lanka and is
                  now housed at the American Museum of Natural History. At the
                  size of a golf ball, the greyish blue star of India is 564
                  carat in weight and contains a unique six-string star caused
                  by the mineral traces in the{' '}
                  <a href={'https://www.bbc.com/'}>Gemstone</a>.
                </Col>
                <br />
                <CardText tag="div">
                  <h6>
                    <u>BRITISH ROYAL JEWEL</u>
                  </h6>
                  <Col lg="12" className="text-left">
                    The British Royal Jewel is a 105 carat Chrysoberyl cat's eye
                    of exceptional quality, discovered in Sri Lanka. It acquired
                    great fame when it was purchased for the British Royalty
                    from a reputed London{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Jeweler</a> around
                    1900. It is said that this cat's eye was cherished by four
                    British monarchs: Edward VII, George V, Edward VIII, and
                    Queen Elizabeth II.
                  </Col>
                  <br />
                  <h6>
                    <u>BISMARCK SAPPHIRE</u>
                  </h6>
                  <Col lg="12" className="text-left">
                    A 98.6 carat cornflower-blue cushion cut{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Sapphire</a>{' '}
                    discovered in Sri Lanka back in 1920. This stone has the
                    most desirable characteristics such deep corn flower blue,
                    symmetrically cut, good transparency and perfect clarity.
                    Some other sources says the stone is found in Burma and
                    actually sold in Sri Lanka.
                  </Col>

                  <br />

                  <h6>
                    <u>PRIDE OF SRI LANKA</u>
                  </h6>
                  <Col lg="12" className="text-left">
                    This Gigantic 856 ct deep blue{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Sapphire</a> has
                    told to be mined in Marapanna 3 km away from Rathnapura in
                    1998. This was mined in pits of Ms. Leelawathi Darmadasa a
                    Major shareholder of this stone. She commented that there
                    were previous cases, the mine in these areas has produced
                    bigger and better stones before, yet those were sold in
                    Black market to avoid tax
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    So these are few interesting facts about{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Sapphires</a> . We
                    hope you would have enjoyed reading this article. Learn and
                    buy gemstones in Canada,Montreal from visiting our website{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International Inc
                    </a>
                    .
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                    is a{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      gemstones dealer
                    </a>
                    (
                    <a href={'https://bijouteriejoyaux.com'}>
                      grossiste pierre précieuse
                    </a>
                    , marchand de pierres précieuses) Company in{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Canada-Montreal</a>{' '}
                    which has{' '}
                    <a
                      href={
                        'https://kids.nationalgeographic.com/science/topic/gemstones'
                      }
                    >
                      Wholesale gemstones
                    </a>{' '}
                    with wide range of Fine Quality Certified Natural{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Presious and Semi Precious gemstones
                    </a>
                    . This is the best place to buy fine quality{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      wholesale gemstones Canada
                    </a>
                    .
                    <br />
                    Visit :
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International Inc
                    </a>
                    <br />
                    <br />
                    Visit{' '}
                    <a
                      href={
                        'https://kids.nationalgeographic.com/science/topic/gemstones'
                      }
                    >
                      National Geographic
                    </a>{' '}
                    to learn gemstones.
                  </Col>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ArticleCard5;
