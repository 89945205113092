import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Col,
  Row,
  CardBody,
  CardTitle,
  CardText,
  CardImg,
  Link,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import GoogleMap from './GoogleMap';
import ContactUsForm from './ContactUsForm';
import { blue } from '../../assets/images';

const ArticleCard1 = () => {
  const { t } = useTranslation();

  return (
    <div className="content-card">
      <Helmet>
        <title>
          Learn about Blue Sapphire - Bijouterie Joyaux Canada - Natural Gems,
          Precious, Semi Precious Stones, Gemstones montreal
        </title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.bijouteriejoyaux.com" />
        <meta
          property="og:title"
          content="Learn about Blue Sapphire - Bijouterie Joyaux
          Canada - Natural Gems, Precious, Semi Precious Stones, Gemstones
          montreal"
        />
        <meta
          property="og:description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality."
        />
        <meta
          name="description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality. "
        />
        <meta
          name="keywords"
          content="Learn Gemstones,Gemstones Montreal,Canada Gemstones,Buy Gemstones Canada, gemstones, wholesale gemstones, gemstone wholesale, gemstone dealer, gem dealer, wholesale gemstone dealer, fine gems dealer, vente de pirre précieuse, grossiste pierre précieuse, marchand de pierres précieuses"
        />
        <link rel="Learn Gems" href="https://www.bijouteriejoyaux.com" />
      </Helmet>
      <Card>
        <Row>
          <Col sm={12} md={6}>
            <CardBody>
              <Card className="contact-us-card">
                <CardTitle className="card-title-learn">
                  Learn about Blue Sapphire
                </CardTitle>
                <CardText>
                  <Card className="contact-us-card">
                    <CardImg
                      alt="Gemstones Mining,Gemstones Canada"
                      src={blue}
                      className="contact-us-card-image8"
                    />
                  </Card>
                  <br />
                  <Col lg="12" className="text-left">
                    Blue sapphire is a valuable primary colour{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      {' '}
                      <a href={'https://bijouteriejoyaux.com'}>Gemstone</a>{' '}
                    </a>{' '}
                    for more information. of the Corondum mineral family. The
                    value of the gemstone rises significantly with the purity of
                    the primary hue.{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Sapphires</a>{' '}
                    belong to the corundum family, the same family where ruby
                    comes from. The very name is likely to bring the image of a
                    blue-coloured stone to your mind. Well, sapphires are not
                    always blue even though the blue variety is the most popular
                    one. Blue sapphires get their alluring colour due to the
                    traces of iron and titanium – which means the more the level
                    of iron and titanium, the higher the saturation.
                  </Col>
                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>Sapphires</a> are
                    dichroic stones, which means that their colour differs
                    according to the viewing angle. From one direction, a blue
                    sapphire may appear violet-blue to blue while from another
                    direction it may appear greenish-blue. It is the cutting of
                    the stone that makes the difference in the finished look. A
                    skilled cutter can align the stones while cutting and ensure
                    that the most beloved blue colour appears through the
                    finished stone.
                  </Col>
                  <br />

                  <Col lg="12" className="text-left">
                    Visit{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouteriejoyaux
                    </a>{' '}
                    for more information.{' '}
                  </Col>
                </CardText>
              </Card>
            </CardBody>
          </Col>
          <Col sm={12} md={6} className="contact-us-right-col">
            <Card className="contact-us-card">
              <CardBody>
                <CardText tag="div">
                  <h6>
                    <u>
                      Color Properties of{' '}
                      <a href={'https://www.bbc.com/'}>
                        {' '}
                        <a href={'https://www.bbc.com/'}>Blue Sapphire</a>{' '}
                      </a>{' '}
                    </u>
                  </h6>
                  <br />
                  <Col lg="12" className="text-left">
                    <a href={'https://www.bbc.com/'}>Blue Sapphires</a> for more
                    information. get their alluring colour due to the traces of
                    iron and titanium – which means the more the level of iron
                    and titanium, the higher the saturation. Green, violet, and
                    purple are the most common secondary hues in blue sapphires.
                    Variations in these secondary hues determine the final look
                    of the gemstone. A sapphire is ‘blue’ only when its
                    secondary hues do not account for more than 15% of its
                    colour. While purple and violet colours can enhance the
                    beautiful bluish look of the stone, green colour can harm
                    the overall appearance of the gem.The inclusion of grey in
                    sapphire can create a steely or cool quality as opposed to
                    the usual warm velvety colour and feel of a sapphire.
                  </Col>
                  <Col lg="12" className="text-left">
                    This is commonly referred to as the grey mask as grey is the
                    normal saturation modifier or mask in blue sapphires. Grey
                    decreases the saturation or the brightness of the stone and
                    therefore has an overall negative effect.
                  </Col>
                  <br />

                  <h6>
                    <u>
                      Inclusions in{' '}
                      <a href={'https://www.nationalgeographic.com/'}>
                        Blue Sapphire
                      </a>{' '}
                    </u>
                  </h6>
                  <Col lg="12" className="text-left">
                    Blue sapphires devoid of inclusions or eye-clean are rare,
                    particularly in large sizes. Yet blue sapphires with small
                    internal inclusions are also valuable, considering the
                    inclusions do not obscure colour, reduce brilliance, or
                    diminish the stone’s elegance. The same holds for silk
                    inclusions. Silk inclusions can even increase the value of
                    blue sapphire.
                  </Col>
                  <br />
                  <h6>
                    <u>Heat Treatment</u>
                  </h6>
                  <Col lg="12" className="text-left">
                    Heat treatment and diffusion are used to make appealing blue
                    sapphires out of common corundum. Over 90 per cent of all
                    blue sapphires in the market has been subject to heat
                    treatment. Heat treatments used to modify clarity and colour
                    of blue sapphires often break down rutile silk. Hence the
                    presence of intact silk would mean that sapphire is not
                    heated. There exist other liquid or solid inclusions that
                    deform or burst under extreme heat and are therefore
                    considered as indicators of untreated stones.
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    Ceylon blue sapphire stone is most popular gemstone among
                    people due to its best astrological effects. Moreover other
                    popular stones are Kashmiri blue sapphire stone, untreated
                    blue sapphire stone. For best result one should only wear a
                    best quality blue sapphire stone.
                  </Col>

                  <br />
                  <Col lg="12" className="text-left">
                    So these are few interesting facts about Blue Sapphire. We
                    hope you would have enjoyed reading this article. Learn and
                    buy gemstones in Canada,Montreal from visiting our website{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                    is a{' '}
                    <a href={'https://www.nationalgeographic.com/'}>
                      gemstones dealer
                    </a>
                    (
                    <a href={'https://bijouteriejoyaux.com'}>
                      grossiste pierre précieuse
                    </a>
                    , marchand de pierres précieuses) Company in{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Canada-Montreal</a>{' '}
                    which has{' '}
                    <a
                      href={
                        'https://kids.nationalgeographic.com/science/topic/gemstones'
                      }
                    >
                      Wholesale gemstones
                    </a>{' '}
                    with wide range of Fine Quality Certified Natural{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Presious and Semi Precious gemstones
                    </a>
                    . This is the best place to buy fine quality{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      wholesale gemstones Canada
                    </a>
                    <br />
                    <br />
                    Visit{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International Inc
                    </a>
                  </Col>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ArticleCard1;
