import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Col,
  Row,
  CardBody,
  CardTitle,
  CardText,
  CardImg,
  Link,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import GoogleMap from './GoogleMap';
import ContactUsForm from './ContactUsForm';
import { gem444 } from '../../assets/images';

const ArticleCard4 = () => {
  const { t } = useTranslation();

  return (
    <div className="content-card">
      <Helmet>
        <title>
          Gem Mining - Bijouterie Joyaux Canada Montreal- Natural Gems,
          Precious, Semi Precious Stones, Gemstones montreal
        </title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.bijouteriejoyaux.com" />
        <meta
          property="og:title"
          content=" Gem Mining - Bijouterie Joyaux
          Canada - Natural Gems, Precious, Semi Precious Stones, Gemstones
          montreal"
        />
        <meta
          property="og:description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality."
        />
        <meta
          name="description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality. "
        />
        <meta
          name="keywords"
          content="Learn Gemstones,Gemstones Montreal,Canada Gemstones,Buy Gemstones Canada, gemstones, wholesale gemstones, gemstone wholesale, gemstone dealer, gem dealer, wholesale gemstone dealer, fine gems dealer, vente de pirre précieuse, grossiste pierre précieuse, marchand de pierres précieuses"
        />
        <link rel="Learn Gems" href="https://www.bijouteriejoyaux.com" />
      </Helmet>
      <Card>
        <CardTitle className="card-title-learn">Gem Mining</CardTitle>
        <Row>
          <Col sm={12} md={6}>
            <CardBody>
              <Card className="contact-us-card">
                <CardText>
                  <Col lg="12" className="text-left">
                    Gemstone localities are found all over the world, so there
                    are no particular locations where only gems are found.
                    However, some states like Srilanka, Mozambique, Tanzania,
                    Madagascar and more have higher quality and more varied gems
                    deposits.
                  </Col>
                  <br />
                  <Card className="contact-us-card">
                    <CardImg
                      alt="Gemstones Mining,Gemstones Canada"
                      src={gem444}
                      className="contact-us-card-image6"
                    />
                  </Card>
                  <br />
                  <h6>
                    <u>
                      Africa’s{' '}
                      <a href={'https://bijouteriejoyaux.com'}>Gemstone</a>{' '}
                      Localities
                    </u>
                  </h6>
                  <Col lg="12" className="text-left">
                    While most people will know that Africa is the major source
                    of diamonds, the colored{' '}
                    <a href={'https://bijouteriejoyaux.com'}>gemstone</a> trade
                    is gradually becoming more significant.
                    <br />
                    Madagascar - is the large island off the southeast coast of
                    Africa. It is a leading supplier of sapphires along with
                    aquamarine, quartz, morganite, tourmaline, tsavorite,
                    demantoid garnet, and labradorite.
                    <br />
                    Meanwhile, Tanzania is a massive source of{' '}
                    <a href={'https://bijouteriejoyaux.com'}>gems</a> . It is
                    particularly well-known for Tanzanite. Tanzanian Spinels
                    from Mahenge have been in the spotlight. The colors of these
                    Spinels can rival the most beautiful Rubies and have been
                    steadily increasing in popularity. Other gemstones from
                    Tanzania include the stunning Rhodolite Garnet, Tsavorite
                    Garnet, and several exclusive stones such as unheated
                    zircon. Mozambique also boasts excellent tourmaline in
                    various colors.
                    <br />
                  </Col>
                  <br />
                  <h6>
                    <u>
                      <a href={'https://bijouteriejoyaux.com'}>
                        North America’s Gemstones
                      </a>{' '}
                    </u>
                  </h6>
                  <Col lg="12" className="text-left">
                    You might be surprised to find out that North America is a
                    source of a wide range of{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Gemstones.</a>
                    Many states have a long history of{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      gemstone mining
                    </a>{' '}
                    including California, Arizona, Nevada, and Oregon. Turquoise
                    is one of the most commonly found gems in North America with
                    mines throughout Arizona and Nevada producing a beautiful
                    range of turquoise in many shades. Other stones found in
                    North America include{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Beryl, Coral, Ruby, Sapphire, Opal, Tourmaline and even
                      Diamonds!
                    </a>{' '}
                  </Col>
                  <br />
                  Visit{' '}
                  <a href={'https://bijouteriejoyaux.com'}>
                    Bijouterie et Joyaux International Inc
                  </a>{' '}
                  for more information
                </CardText>
              </Card>
            </CardBody>
          </Col>
          <Col sm={12} md={6} className="contact-us-right-col">
            <Card className="contact-us-card">
              <CardBody>
                <br />
                <CardText tag="div">
                  <h6>
                    <u>
                      Srilanka the{' '}
                      <a href={'https://bijouteriejoyaux.com'}>Gem Island</a>{' '}
                    </u>
                  </h6>
                  <br />
                  <Col lg="12" className="text-left">
                    Sri Lanka’s gem industry has a very long and colorful
                    history. Sri Lanka was affectionately known as Ratna-Dweepa
                    which means Gem Island. The name is a reflection of its
                    natural wealth.Records from sailors that visited the island
                    states that they brought back “jewels of Serendib”. Serendib
                    was the ancient name given to the island by middle – eastern
                    and Persian traders that crossed the Indian Ocean to trade
                    gems from Sri Lanka to the East during the 4th and 5th
                    century.
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    Throughout history, Sri Lanka has been known as a land of
                    gems. King Solomon was said to have procured a great ruby
                    for the queen of Sheba from Serendip (Sri Lanka’s former
                    name). Marco Polo (1293 AD) wrote about the ruby that once
                    graced the Ruwanweliseya Dagoba at Anuradhapura...”a
                    flawless ruby a span-long and quite as thick as a man’s
                    fist”. It is believed that Sinbad’s visit to the mountains
                    of Serendib, refers to Ratnapura in Sri Lanka.
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    Sri Lanka, formerly Ceylon, remains one of the single most
                    important sources for fine gemstones. Especially notable are{' '}
                    <a
                      href={
                        'https://kids.nationalgeographic.com/science/topic/gemstones'
                      }
                    >
                      Blue Sapphire
                    </a>{' '}
                    ,
                    <a
                      href={
                        'https://kids.nationalgeographic.com/science/topic/gemstones'
                      }
                    >
                      Pink Sapphire
                    </a>
                    ,
                    <a
                      href={
                        'https://kids.nationalgeographic.com/science/topic/gemstones'
                      }
                    >
                      Ruby
                    </a>{' '}
                    ,
                    <a
                      href={
                        'https://kids.nationalgeographic.com/science/topic/gemstones'
                      }
                    >
                      Yellow Sapphire
                    </a>
                    ,
                    <a
                      href={
                        'https://kids.nationalgeographic.com/science/topic/gemstones'
                      }
                    >
                      Alexandrite
                    </a>{' '}
                    ,{' '}
                    <a
                      href={
                        'https://kids.nationalgeographic.com/science/topic/gemstones'
                      }
                    >
                      Cat's-eye chrysoberyl
                    </a>
                    , Almandine and hessonite garnet, Spinel, tourmaline,
                    zircon, moonstone, and quartz are also relatively common.
                    they share their country of origin with several rarer
                    gemstones as well.
                  </Col>
                  <br />
                  <h6>
                    <u>
                      City of <a href={'https://bijouteriejoyaux.com'}>Gems</a>{' '}
                    </u>
                  </h6>
                  <Col lg="12" className="text-left">
                    Ratnapura ("City of Gems" ) It is the capital city of
                    Sabaragamuwa Province, as well as the Ratnapura District,
                    and is a traditional centre for the Sri Lankan gem trade. It
                    is located on the Kalu Ganga (Black River) in south-central
                    Sri Lanka, some 101 km (63 mi) southeast of the country's
                    capital, Colombo. Ratnapura is also spelled as Rathnapura.
                    Every day, a large number of international and local from
                    suburbs and other towns gather in the town centre to sell or
                    buy gemstones.
                  </Col>
                  <br />

                  <Col lg="12" className="text-left">
                    So these are few interesting facts about Gem Mining. We hope
                    you would have enjoyed reading this article. Learn and buy
                    gemstones in Canada,Montreal from visiting our website{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                    is a{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      gemstones dealer
                    </a>
                    (
                    <a href={'https://bijouteriejoyaux.com'}>
                      grossiste pierre précieuse
                    </a>
                    , marchand de pierres précieuses) Company in{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Canada-Montreal</a>{' '}
                    which has{' '}
                    <a
                      href={
                        'https://kids.nationalgeographic.com/science/topic/gemstones'
                      }
                    >
                      Wholesale gemstones
                    </a>{' '}
                    with wide range of Fine Quality Certified Natural{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Presious and Semi Precious gemstones
                    </a>
                    . This is the best place to buy fine quality{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      wholesale gemstones Canada
                    </a>
                    <br />
                    <br />
                    Visit{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International Inc
                    </a>
                  </Col>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ArticleCard4;
