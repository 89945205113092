import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import ContactUsForm from './ContactUsForm';

const Inquiry = ({ lotNumber, label, className, color, hideChatIcon }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const toggle = e => {
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Button color={color} className={className} onClick={toggle}>
        {!hideChatIcon && <ChatIcon />}
        {label}
      </Button>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {t('post-detail-inquiry-button')} - {lotNumber}
        </ModalHeader>
        <ModalBody>
          <ContactUsForm lotNumber={lotNumber} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Inquiry;

const ChatIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      className="inquiry-button-chat-icon"
    >
      <path
        fillRule="evenodd"
        d="M1.75 1A1.75 1.75 0 000 2.75v9.5C0 13.216.784 14 1.75 14H3v1.543a1.457 1.457 0 002.487 1.03L8.061 14h6.189A1.75 1.75 0 0016 12.25v-9.5A1.75 1.75 0 0014.25 1H1.75zM1.5 2.75a.25.25 0 01.25-.25h12.5a.25.25 0 01.25.25v9.5a.25.25 0 01-.25.25h-6.5a.75.75 0 00-.53.22L4.5 15.44v-2.19a.75.75 0 00-.75-.75h-2a.25.25 0 01-.25-.25v-9.5z"
      ></path>
      <path d="M22.5 8.75a.25.25 0 00-.25-.25h-3.5a.75.75 0 010-1.5h3.5c.966 0 1.75.784 1.75 1.75v9.5A1.75 1.75 0 0122.25 20H21v1.543a1.457 1.457 0 01-2.487 1.03L15.939 20H10.75A1.75 1.75 0 019 18.25v-1.465a.75.75 0 011.5 0v1.465c0 .138.112.25.25.25h5.5a.75.75 0 01.53.22l2.72 2.72v-2.19a.75.75 0 01.75-.75h2a.25.25 0 00.25-.25v-9.5z"></path>
    </svg>
  );
};
