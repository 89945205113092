import React from 'react';

const BackButton = ({ handleClick, text }) => {
  return (
    <div className="post-view-back-button-link">
      <button type="button" className="btn btn-link" onClick={handleClick}>
        {text}
      </button>
    </div>
  );
};

export default BackButton;
