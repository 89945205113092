import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Col,
  Row,
  CardBody,
  CardTitle,
  CardText,
  CardImg,
  Link,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import GoogleMap from './GoogleMap';
import ContactUsForm from './ContactUsForm';
import { colorchange } from '../../assets/images';

const ArticleCard13 = () => {
  const { t } = useTranslation();

  return (
    <div className="content-card">
      <Helmet>
        <title>
          Learn about Color Change Sapphire - Bijouterie Joyaux Canada - Natural
          Gems, Precious, Semi Precious Stones, Gemstones montreal
        </title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.bijouteriejoyaux.com" />
        <meta
          property="og:title"
          content="Learn about Color Change Sapphire- Bijouterie Joyaux
          Canada - Natural Gems, Precious, Semi Precious Stones, Gemstones
          montreal"
        />
        <meta
          property="og:description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality."
        />
        <meta
          name="description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality. "
        />
        <meta
          name="keywords"
          content="Learn Gemstones,Gemstones Montreal,Canada Gemstones,Buy Gemstones Canada, gemstones, wholesale gemstones, gemstone wholesale, gemstone dealer, gem dealer, wholesale gemstone dealer, fine gems dealer, vente de pirre précieuse, grossiste pierre précieuse, marchand de pierres précieuses"
        />
        <link rel="Learn Gems" href="https://www.bijouteriejoyaux.com" />
      </Helmet>
      <Card>
        <CardTitle className="card-title-learn">
          Learn about Color Change Sapphire
        </CardTitle>
        <Row>
          <Col sm={12} md={6}>
            <CardBody>
              <Card className="contact-us-card">
                <CardText>
                  <Card className="contact-us-card">
                    <CardImg
                      alt="Gemstones Mining,Gemstones Canada"
                      src={colorchange}
                      className="contact-us-card-image15"
                    />
                  </Card>
                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>Sapphires</a> are
                    not only famous for its stunning blue color, although it has
                    got the capability of reflecting different colors. Since
                    sapphire belongs to the corundum family, thus the part of
                    the <a href={'https://bijouteriejoyaux.com'}>sapphire</a>{' '}
                    that exhibits a different or diverse range of colors is
                    termed as color change sapphire. Despite the fact, that blue
                    is popular to be the best color for sapphires, yet. The type
                    of sapphire produces different colors that range from
                    orange, yellow, pink, purple, etc.
                  </Col>

                  <br />

                  <Col lg="12" className="text-left">
                    The coloring agents are responsible for the reason why color
                    change sapphires alter various colors. Generally, these{' '}
                    <a href={'https://bijouteriejoyaux.com'}>sapphires</a> in a
                    normal daylight alters its color from blue to violet purple
                    color. Despite, the competency of color change sapphire to
                    shift the color of a stone to different color still a normal
                    grade sapphire stone is incompetent to shift the color blue
                    to another color completely; however, it can change the
                    color of{' '}
                    <a href={'https://bijouteriejoyaux.com'}>sapphire</a>{' '}
                    slightly.Hence, only the top grade color change sapphires
                    have the capability to alter the color blue into purple or
                    yellowish completely.
                  </Col>
                </CardText>
              </Card>
            </CardBody>
          </Col>
          <Col sm={12} md={6} className="contact-us-right-col">
            <Card className="contact-us-card">
              <CardBody>
                <CardText tag="div">
                  <h6>
                    <u>What is Color Change ?</u>
                  </h6>
                  <br />
                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>
                      Color-change sapphires
                    </a>{' '}
                    are corundum’s chameleons—stones that change color under
                    different lighting. Under daylight equivalent (fluorescent
                    or LED daylight- balanced) light, the typical color-change
                    sapphire’s basic color ranges from blue to violet. Under
                    incandescent light, it ranges from violetish purple to
                    strongly reddish purple. Some rare color-change{' '}
                    <a href={'https://bijouteriejoyaux.com'}>sapphires</a>{' '}
                    change from green in daylight to reddish brown in
                    incandescent light.
                  </Col>
                  <br />
                  <h6>
                    <u>Colour Change in Daylight :</u>
                  </h6>
                  <br />
                  <Col lg="12" className="text-left">
                    To experience the actual change in color of the sapphire{' '}
                    <a href={'https://bijouteriejoyaux.com'}>gemstone</a> , it
                    should be seen or examined in the presence of daylight or
                    ultraviolet light. Only those sapphire stones can reflect or
                    exhibit different colors whose with two light transmission
                    windows rather than one.
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    For example, have you ever noticed that why{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Blue Sapphire</a>
                    appears blue because it absorbs all other light excluding
                    blue? Similarly, the red coral stone exhibits red color.
                    This stone absorbs or drains all other lights which reflect
                    on the stone except red.
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    The natural daylight observes to be the best or optimum to
                    see the color change properties in the sapphire stone.
                    Because the daylight is having well balance. The color
                    change sapphire comes from the location like Sri Lanka,
                    Brazil, Tanzania, Madagascar, Australia, China, and the USA.
                    The blue sapphire mined or excavated from the Kashmir
                    region, Burma, and China.
                  </Col>
                  <br />
                  <br />
                  <Col lg="12" className="text-left">
                    So these are few interesting facts about Color Change
                    Sapphire. We hope you would have enjoyed reading this
                    article. Learn and buy gemstones in Canada,Montreal from
                    visiting our website{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                    is a{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      gemstones dealer
                    </a>
                    (
                    <a href={'https://bijouteriejoyaux.com'}>
                      grossiste pierre précieuse
                    </a>
                    , marchand de pierres précieuses) Company in{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Canada-Montreal</a>{' '}
                    which has{' '}
                    <a
                      href={
                        'https://kids.nationalgeographic.com/science/topic/gemstones'
                      }
                    >
                      Wholesale gemstones
                    </a>{' '}
                    with wide range of Fine Quality Certified Natural{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Presious and Semi Precious gemstones
                    </a>
                    . This is the best place to buy fine quality{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      wholesale gemstones Canada
                    </a>
                    <br />
                    <br />
                    Visit{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International Inc
                    </a>
                  </Col>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ArticleCard13;
