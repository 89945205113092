import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Col,
  Row,
  CardBody,
  CardTitle,
  CardText,
  CardImg,
  Link,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import GoogleMap from './GoogleMap';
import ContactUsForm from './ContactUsForm';
import { pad } from '../../assets/images';

const ArticleCard10 = () => {
  const { t } = useTranslation();

  return (
    <div className="content-card">
      <Helmet>
        <title>
          Learn about Paparadscha - Bijouterie Joyaux Canada - Natural Gems,
          Precious, Semi Precious Stones, Gemstones montreal
        </title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.bijouteriejoyaux.com" />
        <meta
          property="og:title"
          content="Learn about Paparadscha - Bijouterie Joyaux
          Canada - Natural Gems, Precious, Semi Precious Stones, Gemstones
          montreal"
        />
        <meta
          property="og:description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality."
        />
        <meta
          name="description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality. "
        />
        <meta
          name="keywords"
          content="Learn Gemstones,Gemstones Montreal,Canada Gemstones,Buy Gemstones Canada, gemstones, wholesale gemstones, gemstone wholesale, gemstone dealer, gem dealer, wholesale gemstone dealer, fine gems dealer, vente de pirre précieuse, grossiste pierre précieuse, marchand de pierres précieuses"
        />
        <link rel="Learn Gems" href="https://www.bijouteriejoyaux.com" />
      </Helmet>
      <Card>
        <CardTitle className="card-title-learn">
          Learn about Paparadscha
        </CardTitle>
        <Row>
          <Col sm={12} md={6}>
            <CardBody>
              <Card className="contact-us-card">
                <CardText>
                  <Card className="contact-us-card">
                    <CardImg
                      alt="Gemstones Mining,Gemstones Canada"
                      src={pad}
                      className="contact-us-card-image12"
                    />
                  </Card>
                  <br />
                  <Col lg="12" className="text-left">
                    Rich in history and worn by royalties,{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Sapphires</a> are
                    one of the most recognized gemstones in the world. Many have
                    been captivated by its vast variety of colours ranging from
                    the deep blue to pastel pink. Apart from being spoilt for
                    colours, sapphire is also well known for their phenomenal
                    properties like the colour change, asterism, and{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Padparadscha</a>.
                  </Col>

                  <br />

                  <h6>
                    <u>
                      What you should know about rare and precious{' '}
                      <a href={'https://bijouteriejoyaux.com'}>
                        Padparadscha Gemstone
                      </a>
                      ?
                    </u>
                  </h6>
                  <br />
                  <Col lg="12" className="text-left">
                    Padparadscha is a precious and rare{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Gemstone</a>{' '}
                    belonging to Corundum mineral family. Genuine Padparadscha
                    is also a kind of aluminium-oxide, like its brethren of
                    other sapphires. It is also popular as Orange Sapphire or
                    pink sapphire. Natural Padparadscha are usually found in
                    attractive colours like pink, orange or a very attractive
                    mix of both these colours. Colour variation of this sapphire
                    is attributed to iron and chromium.
                  </Col>

                  <br />
                </CardText>
              </Card>
            </CardBody>
          </Col>
          <Col sm={12} md={6} className="contact-us-right-col">
            <Card className="contact-us-card">
              <CardBody>
                <CardText tag="div">
                  <h6>
                    <u>What is the Natural Color of Padparadscha ?</u>
                  </h6>
                  <Col lg="12" className="text-left">
                    Well, to be honest it is a beautiful and rare sapphire
                    variety and exhibits a beautiful blend of fuchsia, i.e., a
                    perfect blend of orange and pink. Generally, sapphires are
                    blue with a chemical composition of aluminium and oxide.
                    Trace elements of iron and titanium are responsible for
                    blue, the more the iron, the bluer it get. The colour of{' '}
                    <a href={'https://bijouteriejoyaux.com'}>padparadscha</a>{' '}
                    however, is coloured by iron and chromium which is an
                    extremely rare Color combination by nature, resulting in one
                    of the most appealing tones to be seen yet.Unique colour
                    appears like lotus blossom. This gemstone can also be
                    available in other colours like reddish orange, deep orange
                    or pinkish orange and may sometimes have overtones of brown.
                  </Col>
                  <br />
                  <h6>
                    <u>More about Padparadscha Color</u>
                  </h6>
                  <Col lg="12" className="text-left">
                    Debates over colors and definitions extend to{' '}
                    <a href={'https://bijouteriejoyaux.com'}>padparadscha</a>{' '}
                    sapphires, too. Subjective descriptions of these
                    “lotus-colored” sapphires include “sunset,” “peach,” and
                    “salmon.” The preferred color qualities range from a light
                    to medium-tone orange-pink to pink with a slight orange hue
                    to orange with a slight pinkish hue to a more deeply
                    saturated orange-pink. These preferences also vary between
                    consumers from Eastern and Western countries. The term
                    “padparadscha” is derived from the Sinhalese word for an
                    aquatic lotus blossom, which has an unusual salmon color.
                  </Col>
                  <br />
                  <br />
                  <Col lg="12" className="text-left">
                    Many agree that padparadschas straddle the color boundary
                    between pink and orange. Some{' '}
                    <a href={'https://www.bbc.com/'}>padparadscha</a>{' '}
                    <a href={'https://www.bbc.com/'}>Sapphires</a> are not
                    evenly salmon colored, but rather color zoned with pink and
                    yellow.All shades of blue corundum are referred to as simply
                    sapphire and red corundum is called ruby. All other colours
                    are considered fancy coloured sapphires and are named for
                    their particular hues for example: pink sapphire, yellow
                    sapphire, green sapphire etc. The most sought after fancy
                    coloured sapphire is the orangey pink variety known as
                    Padparadscha, which means ‘lotus flower’ in Sinhalese.
                  </Col>
                  <Col lg="12" className="text-left">
                    <a href={'https://www.bbc.com/'}>
                      Natural Padparadscha gems
                    </a>
                    are found in different counties of the world. Some of these
                    places where genuine gemstone is found are- Sri Lanka-Ceylon
                    Tanzania Burma Bangkok.The best variety is found in Sri
                    Lanka-Ceylon. In fact, the most demanded and expensive one
                    is Ceyloni Padparadscha Orange Sapphire.
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    So these are few interesting facts about Padparadscha. We
                    hope you would have enjoyed reading this article. Learn and
                    buy gemstones in Canada,Montreal from visiting our website{' '}
                    <a href={'https://www.bbc.com/'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                    is a{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      gemstones dealer
                    </a>
                    (
                    <a href={'https://bijouteriejoyaux.com'}>
                      grossiste pierre précieuse
                    </a>
                    , marchand de pierres précieuses) Company in{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Canada-Montreal</a>{' '}
                    which has{' '}
                    <a
                      href={
                        'https://kids.nationalgeographic.com/science/topic/gemstones'
                      }
                    >
                      Wholesale gemstones
                    </a>{' '}
                    with wide range of Fine Quality Certified Natural{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Presious and Semi Precious gemstones
                    </a>
                    . This is the best place to buy fine quality{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      wholesale gemstones Canada
                    </a>
                    <br />
                    <br />
                    Visit{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International Inc
                    </a>
                  </Col>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ArticleCard10;
