import React from 'react';
import { Switch, Route } from 'react-router-dom';
import {
  HomePage,
  AboutUsPage,
  Events,
  ContactUs,
  CategoryDetailPage,
  CalibratedSapphire,
  CalibratedSapphireDetail,
} from '../pages';
import LaarnUs from '../pages/Learn';
import PostViewPage from '../pages/PostViewPage';

function Routes() {
  return (
    <Switch>
      <Route exact path="/about-us" component={AboutUsPage} />
      <Route
        exact
        path="/category/:categoryName"
        component={CategoryDetailPage}
      />
      <Route
        exact
        path="/category/:categoryName/:id"
        component={PostViewPage}
      />
      <Route exact path="/calibratedSapphire" component={CalibratedSapphire} />
      <Route
        exact
        path="/calibratedSapphire/detail"
        component={CalibratedSapphireDetail}
      />
      <Route exact path="/events" component={Events} />
      <Route exact path="/contact-us" component={ContactUs} />
      <Route exact path="/learn-with-us" component={LaarnUs} />
      <Route path="/" component={HomePage} />
    </Switch>
  );
}

export default Routes;
