import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Col,
  Row,
  CardBody,
  CardTitle,
  CardText,
  CardImg,
  Link,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import GoogleMap from './GoogleMap';
import ContactUsForm from './ContactUsForm';
import { gem2, sss, gem55, gem66, shutterstock_8 } from '../../assets/images';

const ArticleCard2 = () => {
  const { t } = useTranslation();

  return (
    <div className="content-card">
      <Helmet>
        <title>
          Learn About Sapphire - Bijouterie Joyaux Canada - Natural Gems,
          Precious, Semi Precious Stones, Gemstones montreal
        </title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.bijouteriejoyaux.com" />
        <meta
          property="og:title"
          content="Learn About Gems - Bijouterie Joyaux Canada - Natural Gems, Precious,
        Semi Precious Stones, Gemstones montreal"
        />
        <meta
          property="og:description"
          content="Sapphire is a precious gemstone, a variety of the mineral corundum.
        Among Sapphire,Ruby and Emerald the big three jewelry colored gemstones sapphire has unique properties.Sapphire is generally known as a blue gemstone but surprisingly it comes in a wide range of colors and quality variations."
        />
        <meta
          name="description"
          content="Sapphire is a precious gemstone, a variety of the mineral corundum.
        Among Sapphire,Ruby and Emerald the big three jewelry colored gemstones sapphire has unique properties.Sapphire is generally known as a blue gemstone but surprisingly it comes in a wide range of colors and quality variations. "
        />
        <meta
          name="keywords"
          content="Learn Gemstones,Gemstones Montreal,Canada Gemstones,Buy Gemstones Canada, gemstones, wholesale gemstones, gemstone wholesale, gemstone dealer, gem dealer, wholesale gemstone dealer, fine gems dealer, vente de pirre précieuse, grossiste pierre précieuse, marchand de pierres précieuses"
        />
        <link rel="Learn Gems" href="https://www.bijouteriejoyaux.com" />
      </Helmet>
      <Card>
        {/* <div class="text">🎅🎅🎅🎅🎅🎅🎅🎅🎅🎅</div>
        <ul class="lightrope">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul> */}

        <CardTitle className="card-title-learn">Learn About Sapphire</CardTitle>
        <Row>
          <Col sm={12} md={6}>
            <CardBody>
              <Card className="contact-us-card">
                <CardText>
                  <Card>
                    <CardImg
                      alt="Gemstones Mining,Gemstones Canada"
                      src={shutterstock_8}
                      className="contact-us-card-image3"
                    />
                  </Card>

                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>Sapphires</a> were
                    already known in the 2nd century on the island of Srilanka
                    and by the 4th and 5th century, active international trade
                    was taking places. Its Sapphire mines are thus considered
                    the oldest in the world. It has been home to many of the
                    world’s most famous sapphires, including the 563 carat Star
                    of India, and the 423 carat Logan sapphire.
                    <a href={'https://bijouteriejoyaux.com'}>Sapphires</a> are
                    mined from alluvial deposits or from primary underground
                    workings.
                  </Col>
                  <br />

                  <h6>
                    <u>Properties of Sapphire</u>
                  </h6>
                  <br />
                  <Col lg="12" className="text-left">
                    Sapphire is a precious{' '}
                    <a href={'https://bijouteriejoyaux.com'}>gemstone</a>, a
                    variety of the mineral corundum, consisting of Aluminium
                    Oxide (α-Al2O3) with trace amounts of elements such as Iron,
                    titanium, chromium, vanadium, or magnesium. Sapphire is one
                    of the two gem-varieties of corundum, the other being ruby
                    (defined as corundum in a shade of red).
                    <br /> Visit{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International Inc
                    </a>{' '}
                    for more information.
                    <br />
                    <br />
                    <Card className="contact-us-card">
                      <CardImg
                        alt="Gemstones Mining,Gemstones Canada"
                        src={sss}
                      />
                    </Card>
                    <br />
                    <h6>
                      <u>Sapphire Price factors</u>
                    </h6>
                    <Col lg="12" className="text-left">
                      Sapphire prices are depends on size, color, clarity, cut
                      and overall quality. Completely Untreated Sapphires are
                      more worth than treated sapphires. Geographical Origin of
                      the sapphire also impact on price.
                    </Col>
                    <br />
                    <Col lg="12" className="text-left">
                      Sapphires can be treated by several methods to enhance and
                      improve their clarity and color. It is common practice to
                      heat natural sapphires to improve or enhance their
                      appearance.This is done by heating the sapphires in
                      furnaces to temperatures between 800 and 1,800 °C (1,470
                      and 3,270 °F) for several hours, or even weeks at a time.
                      Rubies and Sapphire are often found in the same
                      geographical settings, but they generally have different
                      geological formations.Every sapphire mine produces a wide
                      range of quality, and origin is not a guarantee of
                      quality.
                    </Col>
                  </Col>
                </CardText>
              </Card>
            </CardBody>
          </Col>
          <Col sm={12} md={6} className="contact-us-right-col">
            <Card className="contact-us-card">
              <CardBody>
                <h6>
                  <u>
                    <a href={'https://bijouteriejoyaux.com'}>
                      Sapphires Around the World
                    </a>
                  </u>
                </h6>

                <Col lg="12" className="text-left">
                  Sapphires are found in : Srilanka, Madagascar,Mozambique,
                  Myanmar (Burma), Nigeria, Rwanda, and Tanzania. Different
                  geographical locations have different appearances or chemical-
                  impurity concentrations, and tend to contain different types
                  of microscopic inclusions in sapphire.
                </Col>
                <br />
                <Card>
                  <CardImg
                    alt="Gemstones Mining,Gemstones Canada"
                    src={gem66}
                    className="contact-us-card-image3"
                  />
                </Card>
                <br />
                <CardText tag="div">
                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>Sapphires</a> can
                    be treated by several methods to enhance and improve their
                    clarity and color. It is common practice to heat natural
                    sapphires to improve or enhance their appearance.This is
                    done by heating the sapphires in furnaces to temperatures
                    between 800 and 1,800 °C (1,470 and 3,270 °F) for several
                    hours, or even weeks at a time. Rubies and Sapphire are
                    often found in the same geographical settings, but they
                    generally have different geological formations.Every
                    sapphire mine produces a wide range of quality, and origin
                    is not a guarantee of quality.
                  </Col>
                  <br />

                  <h6>
                    <u>Sapphire Colors</u>
                  </h6>
                  <br />
                  <Card className="contact-us-card">
                    <CardImg
                      alt="Gemstones Mining,Gemstones Canada"
                      src={gem55}
                      className="contact-us-card-image44"
                    />
                  </Card>
                  <br />
                  <Col lg="12" className="text-left">
                    It is typically blue, but natural "fancy" sapphires also
                    occur in yellow, purple, orange, and green colors; "parti
                    sapphires" show two or more colors. Hue, Saturation and tone
                    are the terms which describes the color of a gemstone. Hue
                    is commonly known as the colour, Saturation refers to
                    brightness and the tone is the lightness or darkness of the
                    stone.Blue sapphire exists in various mixtures of its
                    primary (blue) and secondary hues, various tonal levels
                    (shades) and at various levels of saturation (vividness). If
                    the sapphire color is not blue then it is called as fancy or
                    party colored sapphire such as yellow, orange, green, brown,
                    purple and violet.
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    "parti sapphires" show two or more colors. Although blue is
                    the best-known sapphire color, they occur in other colors,
                    including gray and black, and they can be colorless. A
                    pinkish orange variety of sapphire is called padparadscha.
                    Fancy sapphires are less available than blue sapphires. But
                    fancy includes rainbow of color options which people can
                    make romance with the gemstones. In the 1990s, discoveries
                    in East Africa and Madagascar brought fancy sapphires
                    widespread recognition.
                  </Col>

                  <br />
                  <Col lg="12" className="text-left">
                    So these are few interesting facts about Sapphires. We hope
                    you would have enjoyed reading this article. Learn and buy
                    gemstones in Canada,Montreal from visiting our website{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International Inc
                    </a>
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                    is a{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      gemstones dealer
                    </a>
                    (
                    <a href={'https://bijouteriejoyaux.com'}>
                      grossiste pierre précieuse
                    </a>
                    , marchand de pierres précieuses) Company in{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Canada-Montreal</a>{' '}
                    which has{' '}
                    <a
                      href={
                        'https://kids.nationalgeographic.com/science/topic/gemstones'
                      }
                    >
                      Wholesale gemstones
                    </a>{' '}
                    with wide range of Fine Quality Certified Natural{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Presious and Semi Precious gemstones
                    </a>
                    . This is the best place to buy fine quality{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      wholesale gemstones Canada
                    </a>
                    .
                    <br />
                    Visit :
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International Inc
                    </a>
                    <br />
                    <br />
                    Visit{' '}
                    <a
                      href={
                        'https://kids.nationalgeographic.com/science/topic/gemstones'
                      }
                    >
                      National Geographic
                    </a>{' '}
                    to learn gemstones.
                  </Col>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ArticleCard2;
