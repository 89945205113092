import i18next from 'i18next';
import React from 'react';
import { Container, Row, Card } from 'reactstrap';
import { getLocaleText } from '../../../utils/helperFunctions';
import CategoryCard from '../CategoryCard';

const PreciousStones = ({
  preciousAllData,
  handlePreciousCardClick,
  title,
}) => {
  return (
    <>
      <h2 className="precoius">{title}</h2>
      <Card className="card-stone">
        <Container className="container-card">
          <Row className="row-view-center">
            {preciousAllData.map((category) => {
              const { id, category_name_fr, category_name_en, category_image } =
                category;
              const title = getLocaleText(category_name_en, category_name_fr)[
                i18next.language
              ];
              return (
                <CategoryCard
                  onCardClick={handlePreciousCardClick(category, title)}
                  src={
                    category_image
                      ? category_image.data && category_image.data.full_url
                      : ''
                  }
                  title={title}
                  key={id}
                />
              );
            })}
          </Row>
        </Container>
      </Card>
    </>
  );
};

export default PreciousStones;
