import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Col,
  Row,
  CardBody,
  CardTitle,
  CardText,
  CardImg,
  Link,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import GoogleMap from './GoogleMap';
import ContactUsForm from './ContactUsForm';
import { gem3, shutterstock_7 } from '../../assets/images';

const ArticleCard3 = () => {
  const { t } = useTranslation();

  return (
    <div className="content-card">
      <Helmet>
        <title>
          How to Choose a High Quality Sapphire - Bijouterie Joyaux Canada -
          Natural Gems, Precious, Semi Precious Stones, Gemstones montreal
        </title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.bijouteriejoyaux.com" />
        <meta
          property="og:title"
          content="Learn About History of Gem Trades in Srilanka - Bijouterie Joyaux
          Canada - Natural Gems, Precious, Semi Precious Stones, Gemstones
          montreal"
        />
        <meta
          property="og:description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality."
        />
        <meta
          name="description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality. "
        />
        <meta
          name="keywords"
          content="Learn Gemstones,Gemstones Montreal,Canada Gemstones,Buy Gemstones Canada, gemstones, wholesale gemstones, gemstone wholesale, gemstone dealer, gem dealer, wholesale gemstone dealer, fine gems dealer, vente de pirre précieuse, grossiste pierre précieuse, marchand de pierres précieuses"
        />
        <link rel="Learn Gems" href="https://www.bijouteriejoyaux.com" />
      </Helmet>
      <Card>
        <CardTitle className="card-title-learn">
          How to Choose a High Quality Sapphire
        </CardTitle>
        <Row>
          <Col sm={12} md={6}>
            <CardBody>
              <Card className="contact-us-card">
                <CardText>
                  <Card className="contact-us-card">
                    <CardImg
                      alt="Gemstones Mining,Gemstones Canada"
                      src={gem3}
                      className="contact-us-card-image5"
                    />
                  </Card>
                  <br />
                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>Sapphire</a> is a
                    precious gemstone, a variety of the mineral corundum. Among
                    Sapphire,Ruby and Emerald the big three jewelry colored{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Gemstones</a>{' '}
                    sapphire has unique properties.{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Sapphire</a> is
                    generally known as a blue gemstone but surprisingly it comes
                    in a wide range of colors and quality variations. Sapphires
                    are mined in locations around the world and are a unique{' '}
                    <a href={'https://bijouteriejoyaux.com'}>gemstone</a> in
                    that they are available in a rainbow of colors. Heat,
                    minerals and very specific natural conditions come together
                    to facilitate the formation of a sapphire.In general, the
                    more intense and uniform the color is, the more valuable the
                    stone. We generally think of sapphires as being blue and
                    most of them are blue. The colour blue predominates because
                    magma almost always contains small amounts of titanium which
                    colours the corundum blue. Occasionally, though, the
                    titanium is replaced by other metals and fancy sapphires
                    form in a variety of colours.
                  </Col>
                  <br />

                  <h6>
                    <u> Cut</u>
                  </h6>
                  <Card className="contact-us-card">
                    <CardImg
                      alt="Gemstones Mining,Gemstones Canada"
                      src={shutterstock_7}
                      className="contact-us-card-image5"
                    />
                  </Card>
                  <Col lg="12" className="text-left">
                    A <a href={'https://bijouteriejoyaux.com'}>Sapphire’s</a>{' '}
                    cut is very important. A poor-quality cut leaves a sapphire
                    dull and lacking in colour, while a high-quality cut brings
                    the sapphire to life by enhancing its natural colour and
                    giving it brilliance.There are standardized cuts for
                    diamonds, but not for sapphires. Every sapphire is given a
                    personalized cut to hide its inclusions, capture its best
                    colour and in short, bring out its unique beauty. A well-cut
                    sapphire will always have a well-placed and even table on
                    the crown of the gem and all of its facets will be
                    symmetrical. The depth of a cut sapphire often varies
                    according to its colour tones. Jewellers usually give light
                    coloured sapphires a deep cut to add depth to its colour.
                  </Col>
                  <br />
                </CardText>
              </Card>
            </CardBody>
          </Col>
          <Col sm={12} md={6} className="contact-us-right-col">
            <Card className="contact-us-card">
              <CardBody>
                <br />
                <CardText tag="div">
                  <h6>
                    <u>
                      <a href={'https://bijouteriejoyaux.com'}>
                        How to choose High quality Sapphire
                      </a>{' '}
                    </u>
                  </h6>
                  <br />{' '}
                  <Col lg="12" className="text-left">
                    <h6>
                      <u>Excellent Color</u>
                    </h6>
                    A{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Blue Sapphire’s</a>{' '}
                    colour is evaluated for three specific characteristics: hue,
                    tone, and saturation.
                  </Col>
                  <ol>
                    <li>
                      {' '}
                      <Col lg="12" className="text-left">
                        <h6>
                          <u>Sapphire’s Hue:</u>
                        </h6>
                        A sapphire’s Hue refers to its overall colour. This
                        includes its primary colour and any overtones that it
                        has. The best blue sapphires have uniform colouring with
                        little to no visible zoning.Colour zoning happens when
                        the colour of a gem is uneven and leaves spots or
                        “zones” that are lighter or darker in colour than the
                        rest of the stone.
                      </Col>
                    </li>

                    <li>
                      {' '}
                      <Col lg="12" className="text-left">
                        <h6>
                          <u>Sapphire’s tone :</u>
                        </h6>
                        The tone of a blue sapphire refers to the depth of its
                        colour. A cornflower{' '}
                        <a href={'https://bijouteriejoyaux.com'}>
                          Blue Sapphire
                        </a>{' '}
                        and dark blue sapphire are the same colour, but they
                        have very different tones. The cornflower blue sapphire
                        has a light-medium tone while the dark blue sapphire has
                        a dark tone. Blue sapphires are usually categorized as
                        having a light tone, light-medium tone, medium tone,
                        medium-dark tone or dark tone. As a general rule, blue
                        sapphires with a medium or medium-dark tone have the
                        highest value.
                      </Col>
                    </li>
                    <li>
                      {' '}
                      <Col lg="12" className="text-left">
                        <h6>
                          <u>Sapphire’s saturation :</u>
                        </h6>
                        Saturation is a measure of the intensity or purity of a
                        gem’s hue or color. Saturation refers to the purity of a
                        blue sapphire’s colour. A gem with gray or brown
                        overtones is said to have poor saturation, while a
                        sapphire with a vivid blue colour has good saturation.
                      </Col>
                    </li>
                  </ol>
                  <h6>
                    <u>Look into the Inclusions</u>
                  </h6>
                  <Col lg="12" className="text-left">
                    A variety of different inclusions can form within a natural
                    sapphire. Each has different implications on the gemstone's
                    visibility and sparkle or light performance.
                  </Col>
                  <ol>
                    <li>
                      {' '}
                      <Col lg="12" className="text-left">
                        <h6>
                          <u>Needles:</u>
                        </h6>
                        These are long and thin mineral inclusions. Often times,
                        these needles occur in groups and look like striations
                        in the{' '}
                        <a href={'https://bijouteriejoyaux.com'}>Gemstones</a> .
                      </Col>
                    </li>

                    <li>
                      {' '}
                      <Col lg="12" className="text-left">
                        <h6>
                          <u>Mineral Crystals:</u>
                        </h6>
                        These are light or dark crystals that are trapped within
                        the{' '}
                        <a href={'https://bijouteriejoyaux.com'}>gemstone</a> .
                        When small they do not very negatively affect the look
                        of the sapphire, but it is important to avoid sapphires
                        that have large crystal inclusions.
                      </Col>
                    </li>
                    <li>
                      {' '}
                      <Col lg="12" className="text-left">
                        <h6>
                          <u>Feathers:</u>
                        </h6>
                        These smaller inclusions have the look of wisp or
                        feather and are typically whitish in color. They can
                        impact the structural integrity of the sapphire if the
                        size is large or open.
                      </Col>
                    </li>
                    <li>
                      {' '}
                      <Col lg="12" className="text-left">
                        <h6>
                          <u>Fingerprints:</u>
                        </h6>
                        These are quite common in sapphires. The inclusions are
                        clustered together and look like small human
                        fingerprints.
                      </Col>
                    </li>
                  </ol>
                  <Col lg="12" className="text-left">
                    The overall clarity or purity of a blue sapphire is another
                    factor that determines whether it is a quality gem or not.
                    Clarity has to do with how many and what types of inclusions
                    the sapphire has.Every sapphire has inclusions. These
                    inclusions are small particles inside the gem that are
                    distinctly different from the rest of the sapphire.The best
                    quality sapphires are “eye-clean” which means that none of
                    the inclusions in the gem are visible to the naked eye.
                  </Col>
                  <br />
                  <h6>
                    <u> The price should reflect the carat size</u>
                  </h6>
                  <Col lg="12" className="text-left">
                    Carat is the way which a{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Gemstones</a>{' '}
                    weight is measured.When Carat weight increases the gemstone
                    must reflect its price to high. If it makes a high price
                    with its carat weight we can measure it as a quality
                    gemstone. Blue sapphires that have vivid and uniform
                    colouring with medium to medium- dark tones are usually
                    considered to be the most beautiful. When you pair this
                    beautiful colouring with great clarity and a good cut, you
                    have a high-quality blue sapphire.
                  </Col>
                  <br />
                  <h6>
                    <u> What is “eye clean”?</u>
                  </h6>
                  <Col lg="12" className="text-left">
                    Eye-clean is an expression used to describe the clarity of a
                    Sapphire that is visibly clear to the naked eye. This is an
                    important consideration when it comes to purchasing a
                    Sapphire . So many Sapphires sold online disappoint with
                    noticeable and visible inclusions apparent when viewed with
                    the naked eye. According to GIA and AGS, Sapphires that are
                    considered Flawless (FL), Internally Flawless (IF), Very,
                    Very Slightly Included (VVS1 and VVS2) and Very Slightly
                    Included (VS1 and VS2) are considered eye clean. On the
                    other hand, Slightly Included (SI1 and SI2) Sapphires may or
                    may not be eye clean.
                  </Col>
                  <Col lg="12" className="text-left">
                    So these are few interesting facts about Choosing a High
                    quality Sapphire. We hope you would have enjoyed reading
                    this article. Learn and buy gemstones in Canada,Montreal
                    from visiting our website{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                    is a{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      gemstones dealer
                    </a>
                    (
                    <a href={'https://bijouteriejoyaux.com'}>
                      grossiste pierre précieuse
                    </a>
                    , marchand de pierres précieuses) Company in{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Canada-Montreal</a>{' '}
                    which has{' '}
                    <a
                      href={
                        'https://kids.nationalgeographic.com/science/topic/gemstones'
                      }
                    >
                      Wholesale gemstones
                    </a>{' '}
                    with wide range of Fine Quality Certified Natural{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Presious and Semi Precious gemstones
                    </a>
                    . This is the best place to buy fine quality{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      wholesale gemstones Canada
                    </a>
                    <br />
                    <br />
                    Visit :
                    <a href={'https://bijouteriejoyaux.com'}>
                      {' '}
                      Bijouterie et Joyaux International Inc
                    </a>
                  </Col>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ArticleCard3;
