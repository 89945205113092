import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Col,
  Row,
  CardBody,
  CardTitle,
  CardText,
  CardImg,
  Link,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import GoogleMap from './GoogleMap';
import ContactUsForm from './ContactUsForm';
import { purple } from '../../assets/images';

const ArticleCard13 = () => {
  const { t } = useTranslation();

  return (
    <div className="content-card">
      <Helmet>
        <title>
          Learn about Purple Sapphires - Bijouterie Joyaux Canada - Natural
          Gems, Precious, Semi Precious Stones, Gemstones montreal
        </title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.bijouteriejoyaux.com" />
        <meta
          property="og:title"
          content="Learn about Purple Sapphires - Bijouterie Joyaux
          Canada - Natural Gems, Precious, Semi Precious Stones, Gemstones
          montreal"
        />
        <meta
          property="og:description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality."
        />
        <meta
          name="description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality. "
        />
        <meta
          name="keywords"
          content="Learn Gemstones,Gemstones Montreal,Canada Gemstones,Buy Gemstones Canada, gemstones, wholesale gemstones, gemstone wholesale, gemstone dealer, gem dealer, wholesale gemstone dealer, fine gems dealer, vente de pirre précieuse, grossiste pierre précieuse, marchand de pierres précieuses"
        />
        <link rel="Learn Gems" href="https://www.bijouteriejoyaux.com" />
      </Helmet>
      <Card>
        <CardTitle className="card-title-learn">
          Learn about Purple Sapphires
        </CardTitle>
        <Row>
          <Col sm={12} md={6}>
            <CardBody>
              <Card className="contact-us-card">
                <CardText>
                  <Card className="contact-us-card">
                    <CardImg
                      alt="Gemstones Mining,Gemstones Canada"
                      src={purple}
                      className="contact-us-card-image14"
                    />
                  </Card>
                  <br />
                  <Col lg="12" className="text-left">
                    The tones of{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Purple Sapphires
                    </a>{' '}
                    range from delicate lilac and lavender to deep purple and
                    beyond.Purple sapphires are often under-appreciated, and are
                    far more rare than initially recognised. What makes them
                    particularly special is that they do not need to be heat
                    treated to obtain their best colour. What’s more, many are
                    naturally able to change colour in different lighting.
                  </Col>

                  <br />
                </CardText>
              </Card>
            </CardBody>
          </Col>
          <Col sm={12} md={6} className="contact-us-right-col">
            <Card className="contact-us-card">
              <CardBody>
                <CardText tag="div">
                  <h6>
                    <u>
                      Properties of{' '}
                      <a href={'https://bijouteriejoyaux.com'}>
                        Purple Sapphire
                      </a>
                    </u>
                  </h6>
                  <br />
                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>
                      Purple Sapphires
                    </a>{' '}
                    are part of the corundum group. Gemstones in this group are
                    highly regarded for their hardness. At 9 on the Mohs scale,
                    only diamond and moissanite are harder. This means that
                    owning a purple sapphire doesn’t require additional care and
                    your stone will retain its beauty throughout the years. When
                    it comes to colour, typically the more intense it is (with
                    fewer distracting zones of other colours) the more valuable
                    a stone is.{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Purple Sapphire</a>{' '}
                    have anything from weak to vivid colour saturation.
                  </Col>

                  <br />

                  <h6>
                    <u>
                      Color Properties of{' '}
                      <a href={'https://bijouteriejoyaux.com'}>
                        Purple Sapphire
                      </a>
                    </u>
                  </h6>
                  <Col lg="12" className="text-left">
                    Rarely will you find internally clean{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Sapphire</a>,
                    therefore even expensive stones can have slight inclusions.
                    Highly saturated medium or darker purple gemstones are
                    considered best, and of course the colour-change purple
                    sapphires are highly revered.It is much rarer to find a
                    purple sapphire with high clarity, therefore this adds
                    considerable value. Usually these sapphires have some
                    inclusions, whether it be needles (long thin mineral
                    inclusions), healed fractures or colour banding. In some
                    instances, inclusions can increase a purple sapphire’s
                    value, where in other cases the durability is compromised.
                  </Col>
                  <br />
                  <h6>
                    <u>Cut and Shape</u>
                  </h6>
                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>
                      Purple Sapphires
                    </a>{' '}
                    are often faceted and cut in the round brilliant shape, but
                    there are plenty of other options; including square, emerald
                    cut, oval and pear shaped cuts. The most typical crystal
                    form is a barrel or spindle-shaped hexagonal pyramid.Size
                    does matter when it comes to purple sapphires! These stones
                    can range from hundreds of carats to just a few points, but
                    high-quality stones in larger sizes are rarer these days.
                    Most gem grade sapphires you’ll find weight below 5.00
                    carats. Purple sapphires are considered to bring wisdom of
                    spiritual awakening. Purple is associated with calming and
                    focusing the mind. Those who enjoy studying human nature are
                    often drawn to{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Purple Sapphire</a>
                    , such as philosophers or artists.
                  </Col>

                  <br />
                  <Col lg="12" className="text-left">
                    So these are few interesting facts about Purple Sapphire. We
                    hope you would have enjoyed reading this article. Learn and
                    buy gemstones in Canada,Montreal from visiting our website{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                    is a{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      gemstones dealer
                    </a>
                    (
                    <a href={'https://bijouteriejoyaux.com'}>
                      grossiste pierre précieuse
                    </a>
                    , marchand de pierres précieuses) Company in{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Canada-Montreal</a>{' '}
                    which has{' '}
                    <a
                      href={
                        'https://kids.nationalgeographic.com/science/topic/gemstones'
                      }
                    >
                      Wholesale gemstones
                    </a>{' '}
                    with wide range of Fine Quality Certified Natural{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Presious and Semi Precious gemstones
                    </a>
                    . This is the best place to buy fine quality{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      wholesale gemstones Canada
                    </a>
                    <br />
                    <br />
                    Visit{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International Inc
                    </a>
                  </Col>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ArticleCard13;
