import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Col,
  Row,
  CardBody,
  CardTitle,
  CardText,
  CardImg,
  Link,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import GoogleMap from './GoogleMap';
import ContactUsForm from './ContactUsForm';
import { yellow } from '../../assets/images';

const ArticleCard8 = () => {
  const { t } = useTranslation();

  return (
    <div className="content-card">
      <Helmet>
        <title>
          Learn about Yellow Sapphire - Bijouterie Joyaux Canada - Natural Gems,
          Precious, Semi Precious Stones, Gemstones montreal
        </title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.bijouteriejoyaux.com" />
        <meta
          property="og:title"
          content="Learn about Yellow Sapphire - Bijouterie Joyaux
          Canada - Natural Gems, Precious, Semi Precious Stones, Gemstones
          montreal"
        />
        <meta
          property="og:description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality."
        />
        <meta
          name="description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality. "
        />
        <meta
          name="keywords"
          content="Learn Gemstones,Gemstones Montreal,Canada Gemstones,Buy Gemstones Canada, gemstones, wholesale gemstones, gemstone wholesale, gemstone dealer, gem dealer, wholesale gemstone dealer, fine gems dealer, vente de pirre précieuse, grossiste pierre précieuse, marchand de pierres précieuses"
        />
        <link rel="Learn Gems" href="https://www.bijouteriejoyaux.com" />
      </Helmet>
      <Card>
        <Row>
          <Col sm={12} md={6}>
            <CardBody>
              <Card className="contact-us-card">
                <CardTitle className="card-title-learn">
                  Learn about Yellow Sapphire
                </CardTitle>
                <CardText>
                  <Card className="contact-us-card">
                    <CardImg
                      alt="Gemstones Mining,Gemstones Canada"
                      src={yellow}
                      height="600px"
                      className="contact-us-card-image9"
                    />
                  </Card>
                  <br />
                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>Yellow Sapphire</a>{' '}
                    are some of the most beautiful and sought-after{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Gemstones</a> that
                    come in very clean large sizes. The increasing popularity of
                    yellow diamonds and yellow engagement rings have in-turn
                    increased the demand for yellow sapphires as well. They come
                    in a range of colours between greenish-yellow to bright
                    canary yellow. Although the most popular yellow sapphire is
                    a medium canary yellow, some Asian markets have a high
                    demand for a deep orangish-yellow (whisky colour).A Yellow
                    Sapphire’s “cut” refers mostly to its shape, but also to how
                    well the stone is faceted. The cut quality of colored stones
                    differs from cut quality of Diamonds in that Sapphire is cut
                    to enhance its color. Because Sapphire is cut for color, and
                    not sparkle, Sapphire cut is not graded by angles and
                    proportions.
                  </Col>

                  <br />
                </CardText>
              </Card>
            </CardBody>
          </Col>
          <Col sm={12} md={6} className="contact-us-right-col">
            <Card className="contact-us-card">
              <CardBody>
                <br />
                <CardText tag="div">
                  <h6>
                    <u>Color Properties</u>
                  </h6>
                  <Col lg="12" className="text-left">
                    <a href={'https://www.bbc.com/'}>Yellow Sapphire's</a>{' '}
                    “color” is described in terms of hue (yellow,
                    orangey-yellow, greenish- yellow), tone (how light or dark
                    the color is) and saturation (how vivid or brownish the
                    color is). Its quality is also affected by how consistent
                    the color is. That’s because having a vivid yellow stone
                    with a pale yellow corner detracts from its beauty.
                    Likewise, having a soft yellow stone with a vivid green
                    streakwould be distracting.
                  </Col>
                  <Col lg="12" className="text-left">
                    One of the main reasons for the yellow colour in a sapphire
                    is the presence of iron traces. Hence, the more the iron
                    concentration, the higher the colour saturation. Low-level
                    radiation within the earth or lab-induced irradiation can
                    also create yellow sapphires. However, such{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Gemstones</a> can
                    fade with exposure to light and heat. Yet, heat treatments
                    have expanded the market of yellow sapphires and increased
                    demand. Natural yellow sapphires that are not heated and
                    contain saturation are scarce and rare. They are relatively
                    clean and very bright with excellent light reflection in
                    every lighting condition.
                  </Col>
                  <br />
                  <h6>
                    <u>
                      Color Recommendations for{' '}
                      <a href={'https://bijouteriejoyaux.com'}>
                        Yellow Sapphires
                      </a>{' '}
                    </u>
                  </h6>
                  <br />
                  <Col lg="12" className="text-left">
                    Color is the most important factor when choosing a Sapphire.
                    The most sought-after Yellow{' '}
                    <a href={'https://www.bbc.com/'}>Sapphires</a> are
                    medium-toned, vividly-saturated yellow. Some people refer to
                    these stones as “canary.” In contrast, other couples prefer
                    lighter-toned stones like soft pastels. Yellow Sapphire can
                    also be slightly orangey or slightly lemon-limey. Just
                    depends on what you want! They’re all great!
                  </Col>

                  <br />
                  <h6>
                    <u>Price Factors</u>
                  </h6>
                  <Col lg="12" className="text-left">
                    Yellow sapphires are less expensive than their counterparts
                    in blue, pink, or Padparadscha (a mixture of orange and
                    pink). Therefore, gem cutters tend to give prominence to the
                    brilliance of the stone as opposed to weight retention.
                    Consequently, well-cut yellow sapphires are more common than
                    other sapphire colours. Moreover, yellow sapphires are also
                    available in speciality-cuts like radiant cuts that bring
                    out the exquisite colour of the gem. Sri Lanka is the
                    primary source of yellow sapphires in the world.
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    So these are few interesting facts about Yellow Sapphire. We
                    hope you would have enjoyed reading this article. Learn and
                    buy gemstones in Canada,Montreal from visiting our website{' '}
                    <a href={'https://www.nationalgeographic.com/'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                    is a{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      gemstones dealer
                    </a>
                    (
                    <a href={'https://bijouteriejoyaux.com'}>
                      grossiste pierre précieuse
                    </a>
                    , marchand de pierres précieuses) Company in{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Canada-Montreal</a>{' '}
                    which has{' '}
                    <a
                      href={
                        'https://kids.nationalgeographic.com/science/topic/gemstones'
                      }
                    >
                      Wholesale gemstones
                    </a>{' '}
                    with wide range of Fine Quality Certified Natural{' '}
                    <a href={'https://www.nationalgeographic.com/'}>
                      Presious and Semi Precious gemstones
                    </a>
                    . This is the best place to buy fine quality{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      wholesale gemstones Canada.
                    </a>
                    <br />
                    <br />
                    Visit{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International Inc
                    </a>
                  </Col>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ArticleCard8;
