import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Col,
  Row,
  CardBody,
  CardTitle,
  CardText,
  CardImg,
  Link,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import GoogleMap from './GoogleMap';
import ContactUsForm from './ContactUsForm';
import { shutterstock_10 } from '../../assets/images';

const ArticleCard1 = () => {
  const { t } = useTranslation();

  return (
    <div className="content-card">
      <Helmet>
        <title>
          'Natural and Untreated' mean for sapphire - Bijouterie Joyaux Canada
          Montreal - Natural Gems, Precious, Semi Precious Stones, Gemstones
          montreal
        </title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.bijouteriejoyaux.com" />
        <meta
          property="og:title"
          content="'Natural and Untreated' mean for sapphire - Bijouterie Joyaux - Bijouterie Joyaux
          Canada - Natural Gems, Precious, Semi Precious Stones, Gemstones
          montreal"
        />
        <meta
          property="og:description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality."
        />
        <meta
          name="description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality. "
        />
        <meta
          name="keywords"
          content="Learn Gemstones,Gemstones Montreal,Canada Gemstones,Buy Gemstones Canada, gemstones, wholesale gemstones, gemstone wholesale, gemstone dealer, gem dealer, wholesale gemstone dealer, fine gems dealer, vente de pirre précieuse, grossiste pierre précieuse, marchand de pierres précieuses, Gemstone Montreal, Loose Gemstones"
        />
        <link rel="Learn Gems" href="https://www.bijouteriejoyaux.com" />
      </Helmet>
      <Card>
        <Row>
          <Col sm={12} md={6}>
            <CardBody>
              <Card className="contact-us-card">
                <CardTitle className="card-title-learn">
                  'Natural and Untreated' mean for Sapphire
                </CardTitle>
                <CardText>
                  <Col lg="12" className="text-left">
                    The word “natural” identifies a{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Sapphire</a>{' '}
                    crystal that has been developed in the ground, not
                    synthetically created or treated in a lab. The word
                    “untreated” means that a sapphire has not undergone any heat
                    or chemical treatments to improve its color or clarity.Most
                    sapphires are heated up to a temperature of 1800 C° over
                    several days. This allows for the color and clarity to
                    improve. This is called ‘dry’ heat because nothing is added
                    to the heating chamber other than the sapphire that came
                    from the ground. But there are less ethical treatments that
                    are often done to{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Sapphires</a> that
                    are unfortunately not always disclosed to the consumer.
                  </Col>
                  <br />
                  <Card className="contact-us-card">
                    <CardImg
                      alt="Gemstones Mining,Gemstones Canada"
                      src={shutterstock_10}
                      className="contact-us-card-image3"
                    />
                  </Card>
                  <br />
                  <h6>
                    <u>What is an Untreated Unheated Sapphire?</u>
                  </h6>
                  <br />
                  <Col lg="12" className="text-left">
                    Beauty, rarity and durability are the three factors that
                    make a <a href={'https://bijouteriejoyaux.com'}>Gemstone</a>{' '}
                    valuable and why we fall in love with them.Untreated
                    <a href={'https://bijouteriejoyaux.com'}>Sapphires</a> means
                    those that have not had any form of treatment to enhance
                    their colour or clarity. This means these sapphires are in
                    their purest form; they were mined and simply cut and
                    polished to show their inner beauty.
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    Visit{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International Inc
                    </a>{' '}
                    for more information.{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                  </Col>
                  <br />
                </CardText>
              </Card>
            </CardBody>
          </Col>
          <Col sm={12} md={6} className="contact-us-right-col">
            <Card className="contact-us-card">
              <CardBody>
                <h5>
                  <u>
                    Q & A about{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Sapphires</a>{' '}
                  </u>
                </h5>
                <br />

                <h6>
                  <u>
                    Why heat <a href={'https://www.bbc.com/'}>Sapphires</a> ?
                  </u>
                </h6>
                <Col lg="12" className="text-left">
                  To improve the color, offer various sizes, get a higher return
                  on investment, and offer customers an affordable choice. An
                  individual or company in the early stages of the supply chain
                  felt that their unearthed, pre-heated stone lacked a desirable
                  color. They can choose to leave the stone as-is or mark it for
                  heat treatment. If they were to leave the stone in its natural
                  state they would not reap a financial gain. But if they
                  improve the color via heat treatment, the stone becomes more
                  sellable and they can get a better return on their investment.
                  For the consumer, these stones are a great, lower-cost,
                  alternative to natural, unheated sapphires.
                </Col>
                <br />
                <h6>
                  <u>
                    After heating a{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Sapphire</a> , is
                    the color permanent?
                  </u>
                </h6>
                <br />
                <Col lg="12" className="text-left">
                  Yes, this is a boon for heated sapphires.
                </Col>
                <br />
                <CardText tag="div">
                  <h6>
                    <u>Are all heated sapphires the same?</u>
                  </h6>
                  <Col lg="12" className="text-left">
                    No, and this is important. Heating only improves the color
                    of a gemstone but it is not an equalizer.
                  </Col>
                  <br />
                  <h6>
                    <u>
                      By appearance, is there a difference between natural and
                      heat-treated sapphires?
                    </u>
                  </h6>
                  <Col lg="12" className="text-left">
                    Yes, but to a trained eye. Generally speaking, natural
                    no-heat sapphires will have a softer luster and smoother
                    surface appeal. Heated sapphires display a glass-like
                    surface that feels hard and unnatural.
                  </Col>
                  <br />
                  <h6>
                    <u>
                      Are natural, no-heat sapphires more rare and valuable?
                    </u>
                  </h6>
                  <Col lg="12" className="text-left">
                    Yes, <a href={'https://www.bbc.com/'}>Sapphires</a> that
                    have not undergone any color or clarity enhancement along
                    the supply chain (mine to retail) are rare and therefore
                    more valuable than heated sapphires that are abundant in the
                    market.
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    So these are few interesting facts about Natural and
                    Untreated Sapphire. We hope you would have enjoyed reading
                    this article. Learn and buy gemstones in Canada,Montreal
                    from visiting our website{' '}
                    <a href={'https://www.bbc.com/'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    <a href={'https://www.bbc.com/'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                    is a{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      gemstones dealer
                    </a>
                    (
                    <a href={'https://bijouteriejoyaux.com'}>
                      grossiste pierre précieuse
                    </a>
                    , marchand de pierres précieuses) Company in{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Canada-Montreal</a>{' '}
                    which has{' '}
                    <a
                      href={
                        'https://kids.nationalgeographic.com/science/topic/gemstones'
                      }
                    >
                      Wholesale gemstones
                    </a>{' '}
                    with wide range of Fine Quality Certified Natural{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Presious and Semi Precious gemstones
                    </a>
                    . This is the best place to buy fine quality{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      wholesale gemstones Canada
                    </a>
                    <br />
                    <br />
                    Visit{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International Inc
                    </a>
                  </Col>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ArticleCard1;
