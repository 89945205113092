import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Col,
  Row,
  CardBody,
  CardTitle,
  CardText,
  CardImg,
  Link,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import GoogleMap from './GoogleMap';
import ContactUsForm from './ContactUsForm';
import { rubyyy } from '../../assets/images';

const ArticleCard12 = () => {
  const { t } = useTranslation();

  return (
    <div className="content-card">
      <Helmet>
        <title>
          Learn About Ruby - Bijouterie Joyaux Canada - Natural Gems, Precious,
          Semi Precious Stones, Gemstones montreal
        </title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.bijouteriejoyaux.com" />
        <meta
          property="og:title"
          content="Learn About Ruby - Bijouterie Joyaux
          Canada - Natural Gems, Precious, Semi Precious Stones, Gemstones
          montreal"
        />
        <meta
          property="og:description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality."
        />
        <meta
          name="description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality. "
        />
        <meta
          name="keywords"
          content="Learn Gemstones,Gemstones Montreal,Canada Gemstones,Buy Gemstones Canada, gemstones, wholesale gemstones, gemstone wholesale, gemstone dealer, gem dealer, wholesale gemstone dealer, fine gems dealer, vente de pirre précieuse, grossiste pierre précieuse, marchand de pierres précieuses"
        />
        <link rel="Learn Gems" href="https://www.bijouteriejoyaux.com" />
      </Helmet>
      <Card>
        <Row>
          <Col sm={12} md={6}>
            <CardBody>
              <Card className="contact-us-card">
                <CardTitle className="card-title-learn">
                  Learn About Ruby
                </CardTitle>
                <CardText>
                  <Card className="contact-us-card">
                    <CardImg
                      alt="Gemstones Mining,Gemstones Canada"
                      src={rubyyy}
                      className="contact-us-card-image13"
                    />
                  </Card>
                  <br />
                  <Col lg="12" className="text-left">
                    The ruby is a lustrous, deep red stone that has accrued
                    special and symbolic meaning through time. Through the ages,
                    the ruby has represented nobility, purity, and passion. From
                    ancient times through the modern-day,{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Rubies</a> have
                    been valued by cultures around the world.
                  </Col>

                  <br />

                  <h6>
                    <u>
                      Properties of{' '}
                      <a href={'https://bijouteriejoyaux.com'}>Ruby</a>
                    </u>
                  </h6>
                  <br />
                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>Ruby</a>{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Gemstone</a>
                    composed of transparent red corundum, a mineral form of
                    aluminum oxide, Al2O3. Its colour varies from deep cochineal
                    to pale rose red, in some cases with a tinge of purple; the
                    most valued is a pigeon-blood red. The red colour arises
                    from the replacement of a small number of aluminum atoms by
                    chromium atoms (1 in 5,000). High refractivity is
                    characteristic; when cut and polished, ruby is a brilliant
                    stone, but, because it has weak dispersion, it lacks fire.
                    On exposure to high temperature, ruby becomes green but
                    regains its original colour upon cooling. When subjected to
                    radiant discharge, ruby phosphoresces with a vivid red glow.
                  </Col>

                  <br />
                </CardText>
              </Card>
            </CardBody>
          </Col>
          <Col sm={12} md={6} className="contact-us-right-col">
            <Card className="contact-us-card">
              <CardBody>
                <CardText tag="div">
                  <h6>
                    <u>
                      Color Properties of{' '}
                      <a href={'https://bijouteriejoyaux.com'}>Ruby</a>
                    </u>
                  </h6>
                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>Ruby Gemstones</a>{' '}
                    are valued for their rich, red color that denotes passion.
                    This red hue takes on a variety of shades. Each ruby also
                    has inclusions which makes each ruby unique. Even the name
                    of the ruby was derived from the Latin word for red,
                    “rubens.”{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Gemstones</a>{' '}
                    colors are typically graded based on three factors. Hue,
                    tone and saturation. Hue is the gradation of the color, for
                    example dark or pale. Tone is the degree of absorption and
                    reflection of light. For example tones can range from black
                    to clear. Tone is the major determinant of the intensity of
                    color.Finally, saturation is how much of the color of the
                    stone comprises of the primary color. For rubies, those that
                    are vividly and primarily red are most sought after.
                  </Col>
                  <br />
                  <h6>
                    <u>
                      What are the best Colors of{' '}
                      <a href={'https://bijouteriejoyaux.com'}>Ruby</a>?
                    </u>
                  </h6>
                  <Col lg="12" className="text-left">
                    <a href={'https://www.bbc.com/'}>Ruby</a> colors can range
                    from a deep blackish red to an orangish or pinkish red.
                    Regardless of the combinations of other colors that can be a
                    part of the ruby, the primary color is always red. Rubies
                    that are on the shade of too dark can be the color of a wine
                    color. The darkness of this ruby has a rich color but can
                    have less sparkle. Rubies that pure red are often called
                    “pigeon blood” or burmese red rubies. Their color is a
                    lustrous, sparkling true red color. These rubies are the
                    most prized and valuable of all rubies.
                  </Col>
                  <br />
                  <h6>
                    <u>
                      How to Check Inclusions in{' '}
                      <a href={'https://bijouteriejoyaux.com'}>Ruby</a>?
                    </u>
                  </h6>
                  <Col lg="12" className="text-left">
                    While color is an important aspect, the transparency of a
                    ruby is also an important factor in determining it’s value.
                    Rubies that have many inclusions are difficult to see
                    through. Common inclusions within rubies are called rutile
                    needles. These needles are often referred to as silk as they
                    are slender,and differently placed in each ruby.{' '}
                    <a href={'https://www.bbc.com/'}>Rubies</a> that have these
                    inclusions are not transparent and therefore less valuable.
                    The most valuable rubies have little to no inclusions and
                    are easy to see through.
                  </Col>
                  <br />
                  <h6>
                    <u>Treatments on Ruby</u>
                  </h6>
                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>Rubies</a> can also
                    be treated to improve both their color and their clarity.
                    The most common type of treatment is heat. This can improve
                    the quality and consistency of the color. The appearance of
                    inclusions can also be reduced with heat treatment. Other
                    treatments also exist to fill the fractures and inclusions
                    in a ruby. <a href={'https://www.bbc.com/'}>Rubies</a> can
                    be filled with molten lead glass which has a fairly similar
                    look to real rubies. The majority of rubies are heat treated
                    to improve their appearance. The heat treatment is permanent
                    and is fairly common with all reputable{' '}
                    <a href={'https://bijouteriejoyaux.com'}>ruby</a> sellers.
                    However, be wary of purchasing rubies that have treatments
                    other than heat as they may not last over time. Rubies that
                    are untreated and of high color and clarity carry a large
                    premium and are several times more expensive than treated
                    rubies.
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    So these are few interesting facts about Ruby. We hope you
                    would have enjoyed reading this article. Learn and buy
                    gemstones in Canada,Montreal from visiting our website{' '}
                    <a href={'https://www.bbc.com/'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                    is a{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      gemstones dealer
                    </a>
                    (
                    <a href={'https://bijouteriejoyaux.com'}>
                      grossiste pierre précieuse
                    </a>
                    , marchand de pierres précieuses) Company in{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Canada-Montreal</a>{' '}
                    which has{' '}
                    <a
                      href={
                        'https://kids.nationalgeographic.com/science/topic/gemstones'
                      }
                    >
                      Wholesale gemstones
                    </a>{' '}
                    with wide range of Fine Quality Certified Natural{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Presious and Semi Precious gemstones
                    </a>
                    . This is the best place to buy fine quality{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      wholesale gemstones Canada
                    </a>
                    <br />
                    <br />
                    Visit{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International Inc
                    </a>
                  </Col>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ArticleCard12;
