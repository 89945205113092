import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Col,
  Row,
  CardBody,
  CardTitle,
  CardText,
  CardImg,
  Link,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import GoogleMap from './GoogleMap';
import ContactUsForm from './ContactUsForm';
import { peachh } from '../../assets/images';

const ArticleCard16 = () => {
  const { t } = useTranslation();

  return (
    <div className="content-card">
      <Helmet>
        <title>
          Learn about Peach Sapphire - Bijouterie Joyaux Canada - Natural Gems,
          Precious, Semi Precious Stones, Gemstones montreal
        </title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.bijouteriejoyaux.com" />
        <meta
          property="og:title"
          content="Learn about Peach Sapphire - Bijouterie Joyaux
          Canada - Natural Gems, Precious, Semi Precious Stones, Gemstones
          montreal"
        />
        <meta
          property="og:description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality."
        />
        <meta
          name="description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality. "
        />
        <meta
          name="keywords"
          content="Learn Gemstones,Gemstones Montreal,Canada Gemstones,Buy Gemstones Canada, gemstones, wholesale gemstones, gemstone wholesale, gemstone dealer, gem dealer, wholesale gemstone dealer, fine gems dealer, vente de pirre précieuse, grossiste pierre précieuse, marchand de pierres précieuses"
        />
        <link rel="Learn Gems" href="https://www.bijouteriejoyaux.com" />
      </Helmet>
      <Card>
        <CardTitle className="card-title-learn">
          Learn about Peach Sapphire
        </CardTitle>
        <Row>
          <Col sm={12} md={6}>
            <CardBody>
              <Card className="contact-us-card">
                <CardText>
                  <Card className="contact-us-card">
                    <CardImg
                      alt="Gemstones Mining,Gemstones Canada"
                      src={peachh}
                      className="contact-us-card-image17"
                    />
                  </Card>
                  <br />
                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>Peach Sapphire</a>{' '}
                    is the most trendy and latest wedding ring nowadays. Peach
                    is the color which gives you the warm feeling of love. Peach
                    sapphire is the most beautiful and extremely valuable
                    gemstone. This shade sparkles and gives the life to the
                    gemstone.
                  </Col>
                  <br />
                  <h6>
                    <u>Perfect peach sapphire</u>
                  </h6>
                  <br />
                  <Col lg="12" className="text-left">
                    A well cut and polished{' '}
                    <a href={'https://bijouteriejoyaux.com'}>gemstone</a> has
                    its own beauty. If you want a custom wedding ring with a
                    peach sapphire. We suggest you, to select the perfect shade
                    for you as there are many shades of peach such as the
                    padparadscha, orange peach etc. Clarity is another most
                    important factor which you should consider before selecting
                    the sapphire.This is an important element for pastel shades
                    as their light tones easily reveal inclusions. Any sort of
                    cloudiness can dull their delicate color so you should
                    choose the peach sapphire ring with as few inclusions as
                    possible.
                  </Col>
                  <br />
                </CardText>
              </Card>
            </CardBody>
          </Col>
          <Col sm={12} md={6} className="contact-us-right-col">
            <Card className="contact-us-card">
              <CardBody>
                <CardText tag="div">
                  <h6>
                    <u>Cut and Shape</u>
                  </h6>
                  <br />
                  <Col lg="12" className="text-left">
                    Cuts are also the important factor to consider before buying
                    the peach sapphire rings. Round{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Peach Sapphire</a>:
                    The Round Peach Sapphire is the natural and most popular
                    shape today. Round shape gives you the balancing cut and
                    clarity grades. Round peach sapphire gives your ring a
                    traditional yet the classy look. The peach sapphire happens
                    to be one of the most sought after and rarest shades of
                    sapphire out there. This stone is typically found in places
                    such as Sri Lanka, Madagascar, and Tanzania.
                  </Col>
                  <Col lg="12" className="text-left">
                    The{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Peach Sapphire</a>,
                    also known as the champagne sapphire, has trace elements of
                    iron and chromium, which is what gives the stone their
                    pinkish-orange hue that glimmers in all types of
                    light.Typically known for being paired with a rose gold
                    band, the peachy stone looks just as great with any other
                    colored band, but it’s all about preference. This unique
                    diamond-alternative became trendy a few years ago and it
                    still is a favorite among many because it looks lovely
                    paired with many pastel colors.
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    So these are few interesting facts about{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Peach Sapphire</a>{' '}
                    . We hope you would have enjoyed reading this article. Learn
                    and buy gemstones in Canada,Montreal from visiting our
                    website{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                    is a{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      gemstones dealer
                    </a>
                    (
                    <a href={'https://bijouteriejoyaux.com'}>
                      grossiste pierre précieuse
                    </a>
                    , marchand de pierres précieuses) Company in{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Canada-Montreal</a>{' '}
                    which has{' '}
                    <a
                      href={
                        'https://kids.nationalgeographic.com/science/topic/gemstones'
                      }
                    >
                      Wholesale gemstones
                    </a>{' '}
                    with wide range of Fine Quality Certified Natural{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Presious and Semi Precious gemstones
                    </a>
                    . This is the best place to buy fine quality{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      wholesale gemstones Canada
                    </a>
                    <br />
                    <br />
                    Visit{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International Inc
                    </a>
                  </Col>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ArticleCard16;
