import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Col,
  Row,
  CardBody,
  CardTitle,
  CardText,
  CardImg,
  Link,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import GoogleMap from './GoogleMap';
import ContactUsForm from './ContactUsForm';
import { whitee } from '../../assets/images';

const ArticleCard15 = () => {
  const { t } = useTranslation();

  return (
    <div className="content-card">
      <Helmet>
        <title>
          Learn about White Sapphire - Bijouterie Joyaux Canada - Natural Gems,
          Precious, Semi Precious Stones, Gemstones montreal
        </title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.bijouteriejoyaux.com" />
        <meta
          property="og:title"
          content="Learn about White Sapphire - Bijouterie Joyaux
          Canada - Natural Gems, Precious, Semi Precious Stones, Gemstones
          montreal"
        />
        <meta
          property="og:description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality."
        />
        <meta
          name="description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality. "
        />
        <meta
          name="keywords"
          content="Learn Gemstones,Gemstones Montreal,Canada Gemstones,Buy Gemstones Canada, gemstones, wholesale gemstones, gemstone wholesale, gemstone dealer, gem dealer, wholesale gemstone dealer, fine gems dealer, vente de pirre précieuse, grossiste pierre précieuse, marchand de pierres précieuses"
        />
        <link rel="Learn Gems" href="https://www.bijouteriejoyaux.com" />
      </Helmet>
      <Card>
        <CardTitle className="card-title-learn">
          Learn about White Sapphire
        </CardTitle>
        <Row>
          <Col sm={12} md={6}>
            <CardBody>
              <Card className="contact-us-card">
                <CardText>
                  <Card className="contact-us-card">
                    <CardImg
                      alt="Gemstones Mining,Gemstones Canada"
                      src={whitee}
                      className="contact-us-card-image16"
                    />
                  </Card>
                  <br />
                  <Col lg="12" className="text-left">
                    The mineral corundum is colorless in its pure form. The many
                    color variations are the result of the presence of trace
                    elements such as titanium (blue sapphire), iron (yellow
                    sapphire) or chromium (ruby and pink sapphire). The
                    colorless variety of sapphire is known as white sapphire. In
                    Hindi, this gemstone is also called as the “Safed Pukhraj”.
                    This stone is the alternative of Diamond and carries pure
                    and supreme Venus Energy. The natural occurrence of this
                    stone is very rare and limited.
                  </Col>

                  <br />
                  <Col lg="12" className="text-left">
                    Though the vivid colors of corundum are understandably
                    highly valued, from time to time we come across a fine white
                    sapphire that reminds us just how beautiful colorless
                    corundum can be. Most unheated{' '}
                    <a href={'https://bijouteriejoyaux.com'}>White Sapphries</a>{' '}
                    have a tinge of color, but this fine gem is a pure diamond
                    white. View our video below to appreciate the brilliance of
                    this fine gemstone.
                  </Col>
                  <br />
                </CardText>
              </Card>
            </CardBody>
          </Col>
          <Col sm={12} md={6} className="contact-us-right-col">
            <Card className="contact-us-card">
              <CardBody>
                <CardText tag="div">
                  <h6>
                    <u>About White Sapphire</u>
                  </h6>
                  <br />
                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>White Sapphrie</a>{' '}
                    is a very popular gem. Its beauty is attractive and it makes
                    it the most beautiful jewellery. You can use White Sapphire
                    Stone as a piece of jewellery.White Sapphire Stone has the
                    appearance of a blurred white glow. Like all sapphires,
                    white sapphire has corundum mineral shape and a hardness of
                    9 on the Mohs scale. There is only a diamond in front of it
                    on a list. White Sapphire is a very durable stone. People
                    and scientists believe that this stone has been existing
                    millions of years.
                  </Col>
                  <br />
                  <h6>
                    <u>
                      <a href={'https://bijouteriejoyaux.com'}>
                        White Sapphrie
                      </a>{' '}
                      vs. diamonds
                    </u>
                  </h6>
                  <br />
                  <Col lg="12" className="text-left">
                    The white{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Sapphrie</a>{' '}
                    gemstone and diamond is somewhat same because of their white
                    color and high luster but they have a difference in the
                    rating on the Mohs scale, white sapphire has a rating of
                    nine while the diamond has the rating of 10. The white
                    sapphire gemstone is known as the second hardest mineral
                    found on the earth.
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    So these are few interesting facts about{' '}
                    <a href={'https://bijouteriejoyaux.com'}>White Sapphire</a>{' '}
                    . We hope you would have enjoyed reading this article. Learn
                    and buy gemstones in Canada,Montreal from visiting our
                    website{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                    is a{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      gemstones dealer
                    </a>
                    (
                    <a href={'https://bijouteriejoyaux.com'}>
                      grossiste pierre précieuse
                    </a>
                    , marchand de pierres précieuses) Company in{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Canada-Montreal</a>{' '}
                    which has{' '}
                    <a
                      href={
                        'https://kids.nationalgeographic.com/science/topic/gemstones'
                      }
                    >
                      Wholesale gemstones
                    </a>{' '}
                    with wide range of Fine Quality Certified Natural{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Presious and Semi Precious gemstones
                    </a>
                    . This is the best place to buy fine quality{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      wholesale gemstones Canada
                    </a>
                    <br />
                    <br />
                    Visit{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International Inc
                    </a>
                  </Col>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ArticleCard15;
