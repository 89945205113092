import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Col,
  Row,
  CardBody,
  CardTitle,
  CardText,
  CardImg,
  Link,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import GoogleMap from './GoogleMap';
import ContactUsForm from './ContactUsForm';
import { pink } from '../../assets/images';

const ArticleCard9 = () => {
  const { t } = useTranslation();

  return (
    <div className="content-card">
      <Helmet>
        <title>
          Learn about Pink Sapphire - Bijouterie Joyaux Canada - Natural Gems,
          Precious, Semi Precious Stones, Gemstones montreal
        </title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.bijouteriejoyaux.com" />
        <meta
          property="og:title"
          content="Learn about Pink Sapphire - Bijouterie Joyaux
          Canada - Natural Gems, Precious, Semi Precious Stones, Gemstones
          montreal"
        />
        <meta
          property="og:description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality."
        />
        <meta
          name="description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality. "
        />
        <meta
          name="keywords"
          content="Learn Gemstones,Gemstones Montreal,Canada Gemstones,Buy Gemstones Canada, gemstones, wholesale gemstones, gemstone wholesale, gemstone dealer, gem dealer, wholesale gemstone dealer, fine gems dealer, vente de pirre précieuse, grossiste pierre précieuse, marchand de pierres précieuses"
        />
        <link
          rel="Learn Gems"
          href="https://www.thecanadianencyclopedia.ca/en/article/gemstone"
        />
      </Helmet>
      <Card>
        <CardTitle className="card-title-learn">
          Learn about Pink Sapphire
        </CardTitle>
        <Row>
          <Col sm={12} md={6}>
            <CardBody>
              <Card className="contact-us-card">
                <CardText>
                  <Card className="contact-us-card">
                    <CardImg
                      alt="Gemstones Mining,Gemstones Canada"
                      src={pink}
                      className="contact-us-card-image10"
                    />
                  </Card>
                  <br />
                  <Col lg="12" className="text-left">
                    Whenever we think of{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Sapphires</a> , it
                    is usually the blue color that pops-up our heads. But you’ll
                    be stunned to know how they are available in almost every
                    color in the rainbow! Hues like pink, green, yellow, white
                    are to name a few.{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Pink{' '}
                      <a href={'https://bijouteriejoyaux.com'}>Sapphires</a>
                    </a>{' '}
                    are one of the most stunning gemstones that range from soft
                    to deep pink. They even appear in secondary colors like
                    purplish pink and peach-pink. Pink Sapphire engagement rings
                    look trendy and are always in great demand due to high
                    aesthetic appeal and the metaphysical benefits that come
                    along. Pink sapphires range from intense and vibrant hot
                    pinks to pale pastel pinks. Sapphire gets its pink colour
                    from the small traces of chromium it contains. Low level of
                    chromium creates pink sapphires, and the presence of very
                    high chromium concentration creates a ruby. Sometimes, a
                    pink sapphire{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      <a href={'https://bijouteriejoyaux.com'}>Gemstone</a>
                    </a>{' '}
                    comes with traces of titanium as well. Small amounts of
                    titanium present in the crystal structure can give it a
                    purplish-pink hue. Since pink sapphires are rare, a skilled
                    cutter will only cut the stones that are less than half-
                    carat into calibrated sizes. Instead, the cut tries to
                    retain as much of the stone as possible. In most cases, a
                    mixed cut can maintain the weight of the{' '}
                    <a
                      href={
                        'https://www.thecanadianencyclopedia.ca/en/article/gemstone'
                      }
                    >
                      Gemstone
                    </a>{' '}
                    while also enhancing the beauty of the colour.
                  </Col>

                  <br />
                </CardText>
              </Card>
            </CardBody>
          </Col>
          <Col sm={12} md={6} className="contact-us-right-col">
            <Card className="contact-us-card">
              <CardBody>
                <CardText tag="div">
                  <h6>
                    <u>
                      <a href={'https://bijouteriejoyaux.com'}>Color</a>{' '}
                    </u>
                  </h6>

                  <Col lg="12" className="text-left">
                    The color of sapphire has a massive impact on its price.
                    <a href={'https://bijouteriejoyaux.com'}>Sapphires</a> with
                    bright and deep hue with a tinge of purple fetch high price.
                    Better the color saturation and tone, higher does a sapphire
                    cost. Purple Pink Sapphire, Dark Pink Sapphire, and Hot Pink
                    Sapphire are some of the most demanded varieties of
                    sapphires.
                  </Col>

                  <br />

                  <h6>
                    <u>Clarity</u>
                  </h6>
                  <Col lg="12" className="text-left">
                    Natural{' '}
                    <a
                      href={
                        'https://www.thecanadianencyclopedia.ca/en/article/gemstone'
                      }
                    >
                      Pink Sapphires
                    </a>{' '}
                    tend to have inclusions so{' '}
                    <a
                      href={
                        'https://www.thecanadianencyclopedia.ca/en/article/gemstone'
                      }
                    >
                      Sapphires
                    </a>{' '}
                    with inclusions not visible to the naked eyes or with merely
                    visible inclusions are acceptable. Fine silk (rutile
                    needles), fingerprints, and color bands exist as natural
                    features in pink sapphires. Those without any outwardly
                    visible inclusion are extremely rare and therefore command a
                    strong market value.
                  </Col>
                  <br />
                  <h6>
                    <u>Cut</u>
                  </h6>
                  <Col lg="12" className="text-left">
                    <a
                      href={
                        'https://www.thecanadianencyclopedia.ca/en/article/gemstone'
                      }
                    >
                      Gem Cutters
                    </a>{' '}
                    strive hard to minimize wastage for fancy looking cuts.
                    Hence, faceted gemstones like Cushion-cut Pink Sapphire,
                    Square Pink Sapphire, Trillion cut Pink Sapphire, Marquise
                    Pink{' '}
                    <a
                      href={
                        'https://www.thecanadianencyclopedia.ca/en/article/gemstone'
                      }
                    >
                      Sapphire
                    </a>{' '}
                    cost way more than the stone of similar quality and size but
                    in a custom cut like round or oval.
                  </Col>
                  <br />
                  <h6>
                    <u>Origin</u>
                  </h6>
                  <Col lg="12" className="text-left">
                    Sri Lankan and Burma pink sapphires are deemed most valuable
                    in the market. A maximum number of gemstones from these
                    origins have a rich color with fine saturation and superior
                    clarity. Pink{' '}
                    <a
                      href={
                        'https://www.thecanadianencyclopedia.ca/en/article/gemstone'
                      }
                    >
                      Sapphire
                    </a>{' '}
                    from Tanzania, Pakistan, and Madagascar can also fetch good
                    prices if clarity and color in them co-exist on the same
                    scale.
                  </Col>
                  <Col lg="12" className="text-left">
                    If you are looking to buy a gemstone for yourself or your
                    loved ones, it is highly recommended to look for a genuine
                    source as there are many sellers in the market who are
                    always eager to dupe the customers. Make sure to ask for a
                    gem lab certificate to assure quality and authenticity.
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    So these are few interesting facts about Pink Sapphire. We
                    hope you would have enjoyed reading this article. Learn and
                    buy{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Gemstones in Canada
                    </a>{' '}
                    ,Montreal from visiting our website{' '}
                    <a
                      href={
                        'https://www.thecanadianencyclopedia.ca/en/article/gemstone'
                      }
                    >
                      Bijouterie et Joyaux International
                    </a>{' '}
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                    is a{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      gemstones dealer
                    </a>
                    (
                    <a href={'https://bijouteriejoyaux.com'}>
                      grossiste pierre précieuse
                    </a>
                    , marchand de pierres précieuses) Company in{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Canada-Montreal</a>{' '}
                    which has{' '}
                    <a
                      href={
                        'https://kids.nationalgeographic.com/science/topic/gemstones'
                      }
                    >
                      Wholesale gemstones
                    </a>{' '}
                    with wide range of Fine Quality Certified Natural{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Presious and Semi Precious gemstones
                    </a>
                    . This is the best place to buy fine quality{' '}
                    <a
                      href={
                        'https://www.thecanadianencyclopedia.ca/en/article/gemstone'
                      }
                    >
                      wholesale gemstones Canada
                    </a>
                    <br />
                    <br />
                    Visit{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International Inc
                    </a>
                  </Col>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ArticleCard9;
