import React from 'react';
import { Helmet } from 'react-helmet';
import ArticleCard1 from '../../UI/ArticleCard1';
import ArticleCard2 from '../../UI/ArticleCard2';
import ArticleCard3 from '../../UI/ArticleCard3';
import ArticleCard4 from '../../UI/ArticleCard4';
import ArticleCard5 from '../../UI/ArticleCard5';
import ArticleCard6 from '../../UI/ArticleCard6';
import ArticleCard7 from '../../UI/ArticleCard7';
import ArticleCard8 from '../../UI/ArticleCard8';
import ArticleCard9 from '../../UI/ArticleCard9';
import ArticleCard10 from '../../UI/ArticleCard10';
import ArticleCard11 from '../../UI/ArticleCard11';
import ArticleCard12 from '../../UI/ArticleCard12';
import ArticleCard13 from '../../UI/ArticleCard13';
import ArticleCard14 from '../../UI/ArticleCard14';
import ArticleCard15 from '../../UI/ArticleCard15';
import ArticleCard16 from '../../UI/ArticleCard16';
import ArticleCard17 from '../../UI/ArticleCard17';

function LaarnUs() {
  return (
    <div>
      <Helmet>
        <title>
          Learn About Gems - Bijouterie Joyaux Canada - Natural Gems, Precious,
          Semi Precious Stones, Gemstones montreal
        </title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.bijouteriejoyaux.com" />
        <meta
          property="og:title"
          content="Learn About Gems - Bijouterie Joyaux Canada - Natural Gems, Precious,
          Semi Precious Stones, Gemstones montreal"
        />
        <meta
          property="og:description"
          content="Sapphire is a precious gemstone, a variety of the mineral corundum.
          Among Sapphire,Ruby and Emerald the big three jewelry colored gemstones sapphire has unique properties.Sapphire is generally known as a blue gemstone but surprisingly it comes in a wide range of colors and quality variations."
        />
        <meta
          name="description"
          content="Sapphire is a precious gemstone, a variety of the mineral corundum.
          Among Sapphire,Ruby and Emerald the big three jewelry colored gemstones sapphire has unique properties.Sapphire is generally known as a blue gemstone but surprisingly it comes in a wide range of colors and quality variations. "
        />
        <meta
          name="keywords"
          content="Learn Gemstones,Gemstones Montreal,Canada Gemstones,Buy Gemstones Canada, gemstones, wholesale gemstones, gemstone wholesale, gemstone dealer, gem dealer, wholesale gemstone dealer, fine gems dealer, vente de pirre précieuse, grossiste pierre précieuse, marchand de pierres précieuses"
        />
        <link rel="Learn Gems" href="https://www.bijouteriejoyaux.com" />
      </Helmet>
      <div className="main-container-contact">
        <ArticleCard1 />
        <ArticleCard2 />
        <ArticleCard3 />
        <ArticleCard4 />
        <ArticleCard5 />
        <ArticleCard6 />
        <ArticleCard7 />
        <ArticleCard8 />
        <ArticleCard9 />
        <ArticleCard10 />
        <ArticleCard11 />
        <ArticleCard12 />
        <ArticleCard13 />
        <ArticleCard14 />
        <ArticleCard15 />
        <ArticleCard16 />
        <ArticleCard17 />
      </div>
    </div>
  );
}

export default LaarnUs;
