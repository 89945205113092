import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Col,
  Row,
  CardBody,
  CardTitle,
  CardText,
  CardImg,
  Link,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import GoogleMap from './GoogleMap';
import ContactUsForm from './ContactUsForm';
import { green } from '../../assets/images';

const ArticleCard11 = () => {
  const { t } = useTranslation();

  return (
    <div className="content-card">
      <Helmet>
        <title>
          Learn about Green Sapphires - Bijouterie Joyaux Canada - Natural Gems,
          Precious, Semi Precious Stones, Gemstones montreal
        </title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.bijouteriejoyaux.com" />
        <meta
          property="og:title"
          content="Learn about Green Sapphires- Bijouterie Joyaux
          Canada - Natural Gems, Precious, Semi Precious Stones, Gemstones
          montreal"
        />
        <meta
          property="og:description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality."
        />
        <meta
          name="description"
          content="Gem Industry in Sri Lanka has a great history mainly due to the Island's gem mines and the Srilanka stands today as one of the world's leading gem suppliers with products of unique quality. "
        />
        <meta
          name="keywords"
          content="Learn Gemstones,Gemstones Montreal,Canada Gemstones,Buy Gemstones Canada, gemstones, wholesale gemstones, gemstone wholesale, gemstone dealer, gem dealer, wholesale gemstone dealer, fine gems dealer, vente de pirre précieuse, grossiste pierre précieuse, marchand de pierres précieuses"
        />
        <link rel="Learn Gems" href="https://www.bijouteriejoyaux.com" />
      </Helmet>
      <Card>
        <CardTitle className="card-title-learn">
          Learn about Green Sapphires
        </CardTitle>
        <Row>
          <Col sm={12} md={6}>
            <CardBody>
              <Card className="contact-us-card">
                <CardText>
                  <Card className="contact-us-card">
                    <CardImg
                      alt="Gemstones Mining,Gemstones Canada"
                      src={green}
                      className="contact-us-card-image12"
                    />
                  </Card>
                  <br />
                  <Col lg="12" className="text-left">
                    Green Sapphire{' '}
                    <a href={'https://bijouteriejoyaux.com'}> Green Sapphire</a>{' '}
                    owes its color to the presence of iron in its formation, and
                    ranges in hue from pale to deep rich green, sometimes a
                    yellow-green or blue-green. The color is sometimes the
                    result of both yellow and blue bands which make the gem
                    appear to be green to the eye. Though green is a rare color
                    for <a href={'https://bijouteriejoyaux.com'}> Sapphire</a> ,
                    it has only recently begun to increase in popularity, and is
                    still relatively inexpensive compared to other{' '}
                    <a href={'https://bijouteriejoyaux.com'}> Sapphires</a>.
                  </Col>

                  <br />

                  <h6>
                    <u>Where you can find Green Sapphire ?</u>
                  </h6>
                  <Col lg="12" className="text-left">
                    One of the main questions that people wonder about is where
                    are{' '}
                    <a href={'https://bijouteriejoyaux.com'}>green sapphire</a>{' '}
                    mostly found. Well, this{' '}
                    <a href={'https://bijouteriejoyaux.com'}> Gemstone</a>{' '}
                    occurs in Sri Lanka,Myanmar and Cambodia. Few places in
                    Zimbabwe, Kenya, Tanzania, Brazil, Thailand, and the USA
                    also have a green sapphire. Identifying almost any gemstone
                    is hard, mostly because others come in the same color. For
                    green sapphire, the competition is an emerald. A person who
                    does not know much about{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Gemstones</a> can
                    easily confuse the two.
                  </Col>
                  <br />
                </CardText>
              </Card>
            </CardBody>
          </Col>
          <Col sm={12} md={6} className="contact-us-right-col">
            <Card className="contact-us-card">
              <CardBody>
                <CardText tag="div">
                  <h6>
                    <u>
                      How to Check{' '}
                      <a href={'https://bijouteriejoyaux.com'}>
                        {' '}
                        Green Sapphire
                      </a>{' '}
                      Quality ?
                    </u>
                  </h6>
                  <Col lg="12" className="text-left">
                    The best way to identify a{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Green sapphire</a>{' '}
                    and its quality are by: Checking for scratches – the green
                    sapphire is the second densest of all{' '}
                    <a href={'https://bijouteriejoyaux.com'}> Gemstones</a> ;
                    this means that it is highly durable and its hardness rating
                    will be about 9 on the Mohs scale. This means that any
                    object which has a hardness rating lower than or equal to
                    that of the sapphire will not be able to scratch the stone.
                    Glass, Spinel, and zirconia all have a lower hardness rating
                    than that.
                  </Col>
                  <br />
                  <h6>
                    <u>
                      Color Properties of{' '}
                      <a href={'https://bijouteriejoyaux.com'}>
                        {' '}
                        Green Sapphire
                      </a>{' '}
                    </u>
                  </h6>
                  <br />
                  <Col lg="12" className="text-left">
                    Green Sapphire’s color is because of the presence of iron.
                    This stone ranges in color, from light to dark and rich
                    green, or sometimes, even yellow-green or blue-green. The
                    traces of iron formations present in the green sapphire are
                    the reason that it gets the color green; depending on the
                    level of these formations the color will deepen or become
                    dull. This also makes green sapphires one of the densest
                    gemstones to date. There are times when a gemstone has blue
                    and yellow both present, which makes the stone look green.
                    The green sapphire has only just made its way into the
                    market as it is a rare stone; its popularity is gradually
                    increasing.
                  </Col>

                  <br />

                  <h6>
                    <u>More about Green Sapphire</u>
                  </h6>
                  <Col lg="12" className="text-left">
                    The stone’s color is sometimes caused by both yellow and
                    blue bands that make the gem appear green to the eye.
                    Although green is a very rare color for a Sapphire, its
                    popularity has grown only recently, but it’s still quite
                    affordable compared to other kinds of{' '}
                    <a href={'https://bijouteriejoyaux.com'}> Sapphires</a> .
                    Green Sapphire is an excellent stone when it comes to
                    calming or focusing your mind. It will help you release your
                    mental tensions and undesirable thoughts. It will also
                    encourage you to open your mind to your own beauty and
                    intelligence. It will bring joy and lightness to your life
                    and restore the balance within your body.
                  </Col>
                  <br />
                  <h6>
                    <u>Difference between Green Sapphire and Emerald</u>
                  </h6>
                  <Col lg="12" className="text-left">
                    Two of the major differences between a{' '}
                    <a href={'https://bijouteriejoyaux.com'}>green sapphire</a>{' '}
                    and emerald are that emeralds have a higher price tag, and
                    they are brighter and more vivid than a green sapphire.
                    Their hardness and durability are also quite different from
                    each other (sapphire are 9 Mohs and emeralds are 8 Mohs),
                    emeralds being lesser durable and more susceptible to
                    scratches and cracks.{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Emerald</a> have
                    many flaws and inclusions, which is why they are oiled (to
                    improve their color and clarity), which is a process that
                    green sapphires do not have to go through.
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    So these are few interesting facts about Green Sapphire. We
                    hope you would have enjoyed reading this article. Learn and
                    buy gemstones in Canada,Montreal from visiting our website{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                  </Col>
                  <br />
                  <Col lg="12" className="text-left">
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International
                    </a>{' '}
                    is a{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      gemstones dealer
                    </a>
                    (
                    <a href={'https://bijouteriejoyaux.com'}>
                      grossiste pierre précieuse
                    </a>
                    , marchand de pierres précieuses) Company in{' '}
                    <a href={'https://bijouteriejoyaux.com'}>Canada-Montreal</a>{' '}
                    which has{' '}
                    <a
                      href={
                        'https://kids.nationalgeographic.com/science/topic/gemstones'
                      }
                    >
                      Wholesale gemstones
                    </a>{' '}
                    with wide range of Fine Quality Certified Natural{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Presious and Semi Precious gemstones
                    </a>
                    . This is the best place to buy fine quality{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      wholesale gemstones Canada
                    </a>
                    <br />
                    <br />
                    Visit{' '}
                    <a href={'https://bijouteriejoyaux.com'}>
                      Bijouterie et Joyaux International Inc
                    </a>
                  </Col>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default ArticleCard11;
